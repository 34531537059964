
/* -------------------------------------------------------------------------
 * THEME FAMFUN Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-famfun--enabled {
  .cs-theme-famfun {
    @include color-scheme($cs-theme-famfun)
  }
}
