.c-actionable-list__item-link {
  @include m-body($c-actionable-list__item-link-font);
}

.c-actionable-list__item-room .c-actionable-list__item-room__stock {
  border: unset;
  border-radius: $radius-medium;
  color: $theme-color-neutral-500;
  background-color: $theme-color-neutral-400;
  @include m-body($c-actionable-list__item-label-font);
  font-weight: 600;
  padding: 4px;
}

.c-actionable-list__item-extended__icon:before {
  font-size: 1rem;
}

.c-actionable-list__item-room__details {

  [data-field="title"] {
    @include m-body($c-actionable-list--room--title-font);
  }

  [data-field="description"] {
    @include m-body($c-actionable-list--room--description-font);
  }
}

.c-actionable-list--room [data-c-actionable-list__item_id="NoMoreRooms"] [data-field="title"] {
  @include m-body($c-actionable-list--no-room--title-font);
  font-weight: 600;
}

.c-actionable-list__item-room__price {
  align-self: end;
}

.c-actionable-list--room {
  .c-price__info {
    margin-bottom: 0;
  }

  .c-price__number {
    @include t-text-crop-disable();
  }

  .m-price__currency {
    @include m-heading($c-actionable-list--room__price-title);
  }

  .m-price__value {
    @include m-heading($c-actionable-list--room__price-title);
  }

  .m-price {
    line-height: unset;
  }
}

