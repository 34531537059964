
/* -------------------------------------------------------------------------
 * THEME SELECTIONS Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-selections--enabled {
  .cs-theme-selections {
    @include color-scheme($cs-theme-selections)
  }
}
