
/* -------------------------------------------------------------------------
 * COLORS NEUTRAL
 *
 * Neutral colors are the ones without saturation
 * The values here are for sample proposes, consider add your owns
 *
 */


// Black & White

$f-color__neutral-white:                $color-palette-grey-50 !default;
$f-color__neutral-black:                $color-palette-grey-800 !default;

// Grey shades (Mid tones are missing intentionally)

$f-color__neutral-grey--ultra-light:    $color-palette-grey-100 !default;
$f-color__neutral-grey--light:          $color-palette-grey-200 !default;
$f-color__neutral-grey--semi-light:     $color-palette-grey-300 !default;
$f-color__neutral-grey:                 $color-palette-grey-400 !default;
$f-color__neutral-grey--semi-dark:      $color-palette-grey-500 !default;
$f-color__neutral-grey--dark:           $color-palette-grey-700 !default;
$f-color__neutral-grey--ultra-dark:     $color-palette-grey-900 !default;

// Transparent colors

$f-color__neutral-white--transparent:   rgba($f-color__neutral-white, 0) !default;
$f-color__neutral-black--transparent:   rgba($f-color__neutral-grey--ultra-dark, 0) !default;


// Neutral color map

$f-colors-neutral: (
  "white":                              $f-color__neutral-white,
  "black":                              $f-color__neutral-black,
  "grey--ultra-light":                  $f-color__neutral-grey--ultra-light,
  "grey--light":                        $f-color__neutral-grey--light,
  "grey--semi-light":                   $f-color__neutral-grey--semi-light,
  "grey":                               $f-color__neutral-grey,
  "grey--semi-dark":                    $f-color__neutral-grey--semi-dark,
  "grey--dark":                         $f-color__neutral-grey--dark,
  "grey--ultra-dark":                   $f-color__neutral-grey--ultra-dark,
  "white--transparent":                 $f-color__neutral-white--transparent,
  "black--transparent":                 $f-color__neutral-black--transparent,
) !default;
