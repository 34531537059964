/* -------------------------------------------------------------------------
 * SWITCH COMPONENT
 *
 *
 *
 */

$c-switch--width:                                       44px !default;
$c-switch--height:                                      24px !default;
$c-switch--inner-padding:                               2px !default;
$c-switch--circle-width:                                $c-switch--height - ($c-switch--inner-padding * 2) !default;
$c-switch--radius:                                      $radius-rounded !default;
$c-switch--translate:                                   ($c-switch--width/2) - ($c-switch--inner-padding) !default;

$c-switch--color:                                       $color-palette-green-500 !default;
$c-switch--background-color:                            $color-palette-grey-500 !default;
$c-switch--disabled-opacity:                            $e-form-elements--disabled--opacity !default;
$c-switch--circle-background-color:                     $color-palette-grey-50 !default;

$c-switch__slider--sp-inline:                           $spacing-sm !default;

// Variant boxed
$c-switch--boxed--background-color:                     $f-color__neutral-grey--light !default;
$c-switch--boxed__slider--sp-inset:                     $f-space--small !default;