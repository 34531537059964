/* -------------------------------------------------------------------------
 * CHECKBOX OVERRIDE
 */

$c-checkbox__element--size:                             18px !default;

$c-checkbox__element--border-width:                     2px !default;
$c-checkbox__element--border-style:                     solid !default;
$c-checkbox__element--border-color:                     $color-palette-grey-500 !default;

$c-checkbox__element--pressed--bg-color:                $color-palette-green-500 !default;
$c-checkbox__element--selected--bg-color:               $color-palette-green-500 !default;
$c-checkbox__element--pressed--border-color:            $color-palette-green-500 !default;
$c-checkbox__element--selected--border--color:          $color-palette-green-500 !default;

$c-checkbox__element--border-width:                     2px !default;
$c-checkbox__element--border-style:                     solid !default;
$c-checkbox__element--hover--border-color:              $color-palette-grey-700 !default;

$c-checkbox__checkmark--left:                           15px !default;
$c-checkbox__half-checkmark--top:                       7px !default;

$c-checkbox__element--hover--extra-border-color:        transparent !default;
$c-checkbox__element--pressed--extra-border-color:      transparent !default;
$c-checkbox__element--hover--bg-color:                  transparent !default;
$c-checkbox__element--extra-border--opacity:            0 !default;

$c-checkbox__element--focus--box-shadow:                0px 0px 0px 3px $color-palette-transparent-black-25 !default;
$c-checkbox__element--error-focus--box-shadow:          0px 0px 0px 3px $color-palette-transparent-red-20 !default;
$c-checkbox__element--warning-focus--box-shadow:        0px 0px 0px 3px rgba(244, 190, 21, 0.2) !default;

$c-checkbox__element--error--border-color:              $color-palette-red-500 !default;
$c-checkbox__element--warning--border-color:            $color-palette-yellow-500 !default;