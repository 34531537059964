/* -------------------------------------------------------------------------
* Autocomplete Variables Override
*
*/

$c-autocomplete--border-size:                           0px !default;
$c-autocomplete__font:                                  map_get($m-body__mod-sizes, "medium") !default;
$c-autocomplete__mark--color:                           $color-palette-primary-500 !default;
$c-autocomplete--selected--color:                       $color-palette-grey-800 !default;
$c-autocomplete--selected--bg-color:                    $theme-color-neutral-300 !default;
$c-autocomplete--selected__mark--color:                 $color-palette-primary-500 !default;
$c-autocomplete__icon--color--destinations:             $color-palette-grey-800 !default;
$c-autocomplete__font-subtitle:                         map_get($m-body__mod-sizes, "tiny") !default;
