.c-package-item {
  border-radius: $radius-large;
  box-shadow: $shadow-md-bottom;
  overflow: hidden;

  &:hover {
    box-shadow: $shadow-md-bottom;
  }
}

.c-package-item--listed {
  background-color: $color-palette-grey-50;
}

.c-package-item .c-label.c-label--with-icon.c-label--collection .c-label__icon {
  display: none;
}

.c-package-item .c-label--collection.c-label--with-icon {
  @include m-body(map_get($m-body__mod-sizes, "small"));
  font-weight: $font-weight-semibold;
  height: unset;
  width: unset;
  padding: $spacing-2xs $spacing-xs;
}

.c-package-item .c-label {
  left: $spacing-md;
  top: $spacing-md;
}

.c-package-item--listed .c-label {
  bottom: unset;
  left: $spacing-xs;
  top: $spacing-xs;
}
