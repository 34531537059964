/* -------------------------------------------------------------------------
* Dropdown Variables Override
*
*/

$c-dropdown__icon--position:                                              $spacing-md !default;
$c-dropdown__icon--font-size:                                             $font-size-sm !default;
$c-dropdown__element--text-indent--with-icon:                             36px !default;
$c-dropdown__element--shadow--focus:                                      $e-form-elements--focus--box-shadow !default;
$c-dropdown__messages--font-size:                                         $font-size-xs !default;
$c-dropdown-multiple__swipe--top:                                         7px !default;
$c-dropdown__arrow--color:                                                $color-palette-grey-800 !default;

// Highlighted
$c-dropdown__element--highlighted--value-color:                           $color-palette-grey-800 !default;

// Success
$c-dropdown__element--success--shadow--focus:                             $e-form-elements--focus--box-shadow !default;

// Warning
$c-dropdown__element--warning--shadow--focus:                             $e-form-elements--focus--box-shadow !default;

// Error
$c-dropdown__element--error--shadow--focus:                               $e-form-elements--focus--box-shadow !default;

