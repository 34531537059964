/* -------------------------------------------------------------------------
 * LOADER VARIABLES
 */

$c-brand-loader--size:                          100px !default;
$c-brand-loader--color:                         $f-color__brand-primary !default;
$c-brand-loader__logo--size:                    $c-brand-loader--size / 2 !default;
$c-brand-loader__spark--width:                  $c-brand-loader--size / 15 !default;
$c-brand-loader__spark--height:                 $c-brand-loader--size / 6.2 !default;
$c-brand-loader__spark--rotation:               30deg !default;
$c-brand-loader--positioning:                   50% !default;
$c-brand-loader__spark--animation-duration:     $f-duration--sluggish !default;
$c-brand-loader__spark--animation-delay:        .03s !default;
$c-brand-loader__logo--left:                    3% !default;


// Size modifiers
// -----------------------------------------

$c-brand-loader--proportion--tiny:              .33 !default;

$c-brand-loader--width--tiny:                   $c-brand-loader--size * $c-brand-loader--proportion--tiny !default;
