/* -------------------------------------------------------------------------
 * AIRPORT FILTER
 *
 */

.w-airport-filter.is-disabled {
  .c-dropdown__element {
    background-color: $color-palette-grey-200;

    .c-dropdown__placeholder {
      color: $color-palette-grey-300;
    }
  }

  .c-dropdown__icon {
    background-color: $color-palette-grey-200;
  }

  .c-dropdown__arrow {
    color: $color-palette-grey-300;
  }
}
