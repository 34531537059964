/* -------------------------------------------------------------------------
 * PRICE TABLE OVERRIDE
 */

.c-price-table__navigation-wrapper {
  .c-btn__icon {
    @include t-mq($from: $c-price-table--bp) {
      margin-top: $c-price-table__navigation-wrapper--icon-margin-top;
    }
    &::before {
      font-size: $font-size-xs;
      color: $color-palette-grey-800;
    }
  }

  .c-btn--icon-only[disabled] {
    border-color: $color-palette-grey-300;
    .c-btn__icon::before {
      font-size: $font-size-xs;
      color: $color-palette-grey-300;
    }
  }

  .c-price-table__navigation--left {
    @include t-mq($until: $c-price-table--bp) {
      right: $c-price-table__navigation--left--right;
    }
  }

  .c-price-table__navigation--right {
    @include t-mq($until: $c-price-table--bp) {
      right: $c-price-table__navigation--right--right;
    }
    .c-btn__icon {
      &::before {
        @include t-mq($from: $c-price-table--bp) {
          left: unset;
        }
      }
    }
  }
}

.c-price-table__room-trigger .c-collapse__trigger-icon.m-icon:before {
  color: $color-palette-grey-800;
}

.c-price-table__room-trigger:hover {
  .c-collapse__trigger-icon.m-icon:before {
    color: $color-palette-primary-700;
  }
}

.c-price-table__room-trigger .c-collapse__trigger-icon.m-icon {
  position: relative;
  top: -5px;
}

.c-price-table__navigation:hover {
  .c-btn__icon::before {
    color: $color-palette-primary-700;
  }
}

.c-price-table__legend_item_description {
  text-transform: unset;
}
