/* -------------------------------------------------------------------------
 * HEADING variables
 *
 */


// Material toggling

$m-heading--enabled: true !default;


// Material default properties

$m-heading__properties: (
  "font-family" :         $f-font-family__secondary,
  "font-family-crop" :    $f-font-family__secondary--crop,
  "font-size":            $typography-heading-md-size,
  "line-height":          $typography-heading-md-line-height,
  "font-style":           $typography-heading-md-style,
  "font-weight":          $typography-heading-md-weight,
  "letter-spacing":       $typography-heading-md-letter-spacing
) !default;


// Material Size Modifiers

$m-heading__mod-sizes--enabled : true !default;
$m-heading__mod-sizes          : (
  "micro": (
    "font-family"       : $e-document__body--font-family,
    "font-family-crop"  : $e-document__body--font-family--crop,
    "font-weight"       : 400,
    "font-size"         : $f-font-size--s,
    "line-height"       : $e-document__body--line-height
  ),
  "tiny": (
    "font-family" :       $f-font-family__secondary,
    "font-family-crop" :  $f-font-family__secondary--crop,
    "font-weight" :       700,
    "font-size" :         $f-font-size--m,
    "line-height" :       $f-line-height--tiny
  ),
  "small": (
    "font-family" :       $f-font-family__secondary,
    "font-family-crop" :  $f-font-family__secondary--crop,
    "font-weight" :       700,
    "font-size" :         $f-font-size--l,
    "line-height" :       $f-line-height--tiny
  ),
  "medium" :              $m-heading__properties,
  "large": (
    "font-family" :       $f-font-family__secondary,
    "font-family-crop" :  $f-font-family__secondary--crop,
    "font-size":          $typography-heading-lg-size,
    "line-height":        $typography-heading-lg-line-height,
    "font-style":         $typography-heading-lg-style,
    "font-weight":        $typography-heading-lg-weight,
    "letter-spacing":     $typography-heading-lg-letter-spacing
  ),
  "huge": (
    "font-family" :       $f-font-family__secondary,
    "font-family-crop" :  $f-font-family__secondary--crop,
    "font-size":          $typography-heading-xl-size,
    "line-height":        $typography-heading-xl-line-height,
    "font-style":         $typography-heading-xl-style,
    "font-weight":        $typography-heading-xl-weight,
    "letter-spacing":     $typography-heading-xl-letter-spacing
  )
) !default;
