.w-promoted-price .c-price__info {
  justify-content: flex-end;
}

[class*="w-promoted-price--floating"] .c-price__info {
  justify-content: center;
}

@each $_breakpoint in $w-promoted-price--floating--breakpoints {
  @include t-mq($until: $_breakpoint) {
    .w-promoted-price--floating#{s-core-string-breakpoint($_breakpoint)} {
      .c-price__info {
        justify-content: flex-end;
      }
    }
  }
}
