.fr-faq-categories__grid-item__list-item
  .fr-faq-categories__grid-item__list-item-link {
  font-weight: $font-weight-medium;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.t-faq__actionable-list::before,
.t-faq__actionable-list::after {
  display: none;
}
