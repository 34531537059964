
/* -------------------------------------------------------------------------
 * COLORS Accessibility
 *
 * Accessibility colors are the ones used to improve accessibility in some cases
 * The values here are for sample proposes, consider add your owns
 *
 */

$f-color__accessibility-grey:           #797676 !default;

// Accessibility color map

$f-colors-accessibility: (
  "grey":                               $f-color__accessibility-grey,
) !default;
