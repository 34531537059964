/* -------------------------------------------------------------------------
*
* Voucher
*/

.w-voucher__code {
  background-color: $theme-color-neutral-100;
  border-radius: 0px 0px $spacing-xs $spacing-xs;

  &:hover {
    background-color: $theme-color-neutral-300;
  }
}

.w-voucher__inner-content {
  border-radius: $spacing-xs $spacing-xs 0px 0px;
}

.w-voucher--compact .w-voucher__inner-content {
  border-radius: unset;
}

.w-voucher.w-voucher--compact {
  .w-voucher__balance {
    @include sp-stack-children($f-space--none);
  }
}

.w-voucher__balance {
  @include sp-stack-children($f-space--small);
}

.w-voucher__container {
  .w-voucher__balance_bonus_info_label {
    @include m-body(map_get($m-body__mod-sizes, "tiny"));
    background-color: map_get($cs-state-info, 'background');
    color: map_get($cs-state-info, 'text');
    font-weight: $font-weight-semibold;
  }
}

.w-voucher__code-tooltip::after {
  content: unset;
}

.w-voucher__code-tooltip {
  @include m-body(map_get($m-body__mod-sizes, "small"));
  border-radius: $radius-large;
}
