/* -------------------------------------------------------------------------
 * STEP INDICATOR Override
 */

.c-steps{
  &.c-steps--progress{
    .c-steps__item{
      .c-steps__link{
        font-family: $typography-body-xs-typeface;
        font-size: $typography-body-xs-size;
        line-height: $typography-body-xs-line-height;
        font-style: $typography-body-xs-style;
        font-weight: $typography-body-xs-weight;
        letter-spacing: $typography-body-xs-letter-spacing;
        &:hover{
          .c-steps--progress__bullet{
            color: $color-palette-grey-50;
            background-color: $c-steps--progress__bullet-bg-color--active;
            box-shadow: none;
          }
        }
      }
      .c-steps--progress__bullet{
        background-color: $color-palette-primary-500;
        color: $color-palette-grey-50;
        border-radius: $radius-rounded;
        font-family: $typography-body-xs-typeface;
        font-size: 14px;
        line-height: $typography-body-xs-line-height;
        font-style: $typography-body-xs-style;
        letter-spacing: $typography-body-xs-letter-spacing;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .c-steps--progress__bullet-icon{
        ::before{
          font-weight: $font-weight-bold;
        }
      }
      &.is-active{
        .c-steps--progress__bullet{
          color: $color-palette-grey-50;
          background-color: $c-steps--progress__bullet-bg-color--active;
          box-shadow: none;
        }
      }
      &.is-disabled{
        &::before{
          border-top-style: solid;
        }
      }
    }
  }
}
