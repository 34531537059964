/* -------------------------------------------------------------------------
 * CARD COLLECTION
 *
 */

// Card Collection labels with icon (spark) should be positioned
.c-card__header-label {
  &.c-label--with-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
