.c-absolute-dialog {
  border-radius: $radius-large;
  padding: $spacing-md;
}

.c-absolute-dialog__title {
  font-family: $f-font-family__primary;
  font-size: $typography-label-size;
  font-style: $typography-label-style;
  font-weight: $typography-label-weight;
  line-height: $typography-label-line-height;
  padding-bottom: 0;
  @include t-text-crop-disable();
}

.c-absolute-dialog__header {
  margin-bottom: 0;
}

.c-absolute-dialog__body {
  @include m-body($c-absolute-dialog__body-font);
  @include t-text-crop-disable();
}

.c-absolute-dialog--default {
  background-color: $theme-color-neutral-200;
  border-color: $theme-color-neutral-400;
  color: $color-palette-grey-800;
}

.c-absolute-dialog--closable {
  padding-right: 52px;
}


.c-absolute-dialog--closable .c-absolute-dialog__close {
  top: 50%;
  transform: translateY(-50%);
  padding-right: 16px;
}

.c-absolute-dialog[class*="cs-state"] {

  .c-absolute-dialog__header,
  .c-absolute-dialog__header + .c-absolute-dialog__content {
    padding-left: 36px;
  }

  .c-absolute-dialog__title-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: $font-size-xl;
  }
}

