
/* -------------------------------------------------------------------------
 * RADIUS
 *
 * Border radius sizes
 * The values here are for sample proposes, consider add your owns
 *
 */


// Main radius

$f-radius:                    $radius-medium !default;

// Radius variants

$f-radius--none:              $radius-none !default;
$f-radius--small:             $radius-medium !default;
$f-radius--medium:            $radius-medium !default;
$f-radius--large:             $radius-large !default;
$f-radius--ellipsis:          50% !default;
$f-radius--rounded:           $radius-rounded !default;
