$w-booking-cancellation-flow__prices-summary--background-color:                    $color-palette-grey-50 !default;
$w-booking-cancellation-flow__prices-summary--border-color:                        unset !default;
$w-booking-cancellation-flow__prices-summary--border-width:                        0 !default;

$w-booking-cancellation-flow__prices-summary__support-item--color:                 $theme-color-neutral-500 !default;

$w-booking-cancellation-flow__prices-summary-label--font-size:                     $f-font-size--xs !default;

$w-booking-cancellation-flow__buttons-with-bg--color:                              $f-color__neutral-white !default;
$w-booking-cancellation-flow__buttons-with-bg--margin:                             $f-space--medium !default;
$w-booking-cancellation-flow__buttons-with-bg--padding:                            $spacing-md !default;
$w-booking-cancellation-flow__buttons-with-bg--shadow:                             $shadow-md-top !default;

$w-booking-cancellation-flow__prices-summary--box-shadow:                          $shadow-md-bottom !default;
$w-booking-cancellation-flow__prices-summary--border-radius:                       $radius-large !default;
