/* -------------------------------------------------------------------------
 * THEME SKI IN SKI OUT Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-ski_in_ski_out--enabled {
  .cs-theme-ski_in_ski_out {
    @include color-scheme($cs-theme-ski_in_ski_out)
  }
}
