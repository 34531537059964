/* -------------------------------------------------------------------------
 * Switch OVERRIDE
 */
.c-switch__circle {
  transition: all .1s $c-switch--transition;
}
.c-switch__circle:before {
  box-shadow: unset;
  transition: all .1s $c-switch--transition;
}

.c-switch__slider{
  &:hover {
    .c-switch__circle {
      background-color: $color-palette-grey-700;
    }
    .c-switch__checkbox:checked + .c-switch__circle {
      background-color: $color-palette-green-700;
    }
  }
}

.c-switch__checkbox:focus + .c-switch__circle {
  box-shadow: $e-form-elements--focus--box-shadow;
}

.c-switch__text{
  color: $theme-color-neutral-800;
  font-family: $typography-label-typeface;
  font-size: $typography-label-size;
  font-style: $typography-label-style;
  font-weight: $typography-label-weight;
  line-height:$typography-label-line-height;
  letter-spacing: $typography-label-letter-spacing;
}

.c-switch{
  &.is-disabled{
    .c-switch__circle{
      background-color: $color-palette-grey-200;
    }
    .c-switch__text{
      color: $color-palette-grey-300
    }

  }
}
