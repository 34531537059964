.c-photo-gallery__item {
  &:hover,
  &:focus {
    .c-img {
      box-shadow: $shadow-lg-bottom;
    }
  }
}

.c-photo-gallery-video-thumbnail-info-icon {
  border: 2px solid $color-palette-grey-300;
}

.c-photo-gallery__item-video:hover .c-photo-gallery-video-thumbnail-info-icon {
  border-color: $color-palette-grey-500;
}

.c-photo-gallery {
  .c-modal__header {
    box-shadow: unset;
    .c-modal__header-text {
      color: $color-palette-grey-50;
    }
    .c-modal__close {
      color: $color-palette-grey-50;
      &:hover,
      &:active,
      &:focus {
        color: $color-palette-primary-700;
      }
    }
  }
  .c-modal__body {
    background-color: unset;
  }
}
