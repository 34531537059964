
$f-font-family__general-sans:               'General Sans' !default;
$f-font-family__general-sans--fallback:     Arial, Sans-serif !default;

$f-font-family__general-sans--crop: (
  "font-size":            40,
  "line-height":          1,
  "top":                  7,
  "bottom":               5,
) !default;

@font-face {
  font-family: $f-font-family__general-sans;
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('General Sans Medium'), local('General-Sans-Medium'),
  url('#{$s-config__path--fonts}/general-sans/general-sans--medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/general-sans/general-sans--medium.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("general-sans--medium": "#{$s-config__path--fonts}/general-sans/general-sans--medium.woff2"));
}


@font-face {
  font-family: $f-font-family__general-sans;
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('General Sans Semibold'), local('General-Sans-Semibold'),
  url('#{$s-config__path--fonts}/general-sans/general-sans--semi-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/general-sans/general-sans--semi-bold.woff') format('woff'); /* Modern Browsers */
}


@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("general-sans--semi-bold": "#{$s-config__path--fonts}/general-sans/general-sans--bold.woff2"));
}
