.fr-header-menu-top {
    .c-nav__link {
        color: $fr-header-menu-top__nav-item---link-color;

        &:hover {
            color: $fr-header-menu-top__nav-item---link-color--hover;
            text-decoration: $fr-header-menu-top__nav-item---link-decoration--hover;
        }
    }
}
