/* -------------------------------------------------------------------------
 * NUMBER STEPPER
 *
 */

.c-number-stepper__body {
 	display: inline-flex;
	padding: 0px;
	justify-content: center;
	align-items: center;
	gap: $spacing-2xs;

  .c-btn {
    &.c-btn--icon-only{
      &:hover, &:focus {
        background-color: $c-number-stepper__btn--hover--bg-color;
        color: $color-palette-grey-50;
      }
      .c-btn__icon{
        height: $c-number-stepper--height;
        width: $c-number-stepper--height;
        padding-left: 0;
      }
      &[disabled]{
        color: $color-palette-grey-300;
        background-color: $color-palette-grey-200;
  
      }
      .c-btn__icon {
        &::#{$m-icons__pseudo-element} {
          font-size: $c-number-stepper__input--font-size;
          left: calc(50%);
        }
      }
    }
  }
  .c-number-stepper__input {
    font-family: $typography-body-md-typeface;
    font-size: $typography-body-md-size;
    font-style: $typography-body-md-style;
    font-weight: $typography-body-md-weight;
    line-height: $typography-body-md-line-height;
    letter-spacing: $typography-body-md-letter-spacing;
  
    &:active, &:focus{
      outline: none;
    }
  }
}
