/* -------------------------------------------------------------------------
 * NAV
 */

@import 'vars-override';

$c-nav--sp-stack:                                   $f-space--tiny * 1.5 !default;

$c-nav__link--color:                                $e-document__body--color !default;
$c-nav__link--hover--color:                         $f-color__brand-primary--semi-dark !default;

$c-nav__list--margin-top:                           $c-nav--sp-stack * .75 !default;


// Variants
// ---------------------

// Block

$c-nav--block--sp-inset-h:                          $f-space--small * 1.5 !default;
$c-nav--block--sp-inset-v:                          $f-space--tiny !default;

$c-nav__link--block--color:                         $e-document__body--color !default;

$c-nav__link--block--hover--bg-color:               $f-color__neutral-grey--ultra-light !default;
$c-nav__link--block--pressed--color:                $f-color__neutral-grey--light !default;
$c-nav__link--block--active--bg-color:              $f-color__neutral-grey--light !default;
$c-nav__link--block--active--font-weight:           700 !default;

$c-nav__back--block--color:                         $f-color__neutral-grey--semi-dark !default;


// Dropdown

$c-nav__list--dropdown-border-color:                $f-color__brand-primary !default;
$c-nav__link--dropdown--hover--bg-color:            $f-color__neutral-grey--ultra-light !default;
$c-nav__link--dropdown--pressed--bg-color:          $f-color__neutral-grey--light !default;

$c-nav__link--dropdown--font-weight--level-1:       700 !default;
$c-nav__link--dropdown--hover--bg-color:            $f-color__neutral-grey--ultra-light !default;
$c-nav__link--dropdown--pressed--color:             $f-color__neutral-grey--light !default;
$c-nav__link--dropdown--active--bg-color:           $f-color__neutral-grey--light !default;
$c-nav__link--dropdown--active--font-weight:        700 !default;


// List

$c-nav--list--sp-inset-h:                          $f-space--small * 1.5 !default;
$c-nav--list--sp-inset-v:                          $f-space--tiny !default;

$c-nav__item--list--sp-stack:                      $f-space--tiny !default;

$c-nav__link--list--color:                         $e-document__body--color !default;
$c-nav__link--list--bg-color:                      $f-color__neutral-grey--ultra-light !default;
$c-nav__link--list--pressed--color:                $f-color__neutral-grey--semi-light !default;
$c-nav__link--list--hover--bg-color:               $f-color__neutral-grey--light !default;

$c-nav__back--list--color:                         $f-color__brand-primary--semi-dark !default;
