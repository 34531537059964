.c-accordion {
  color: $color-palette-grey-800;
}

.c-accordion .c-accordion__item-title {
  font-size: $typography-body-md-bold-size;
  font-family: $f-font-family__primary;
  font-weight: $typography-body-md-bold-weight;
  line-height: $typography-body-md-bold-line-height;
  letter-spacing: $typography-body-md-bold-letter-spacing;
  @include t-text-crop-disable();
}

.c-accordion__item-heading,
.c-accordion--small .c-accordion__item-heading {
  padding-left: 0;
  padding-right: 0;

  @include t-mq($from: $c-accordion--bp) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-accordion .c-accordion__item-trigger {
  right: 0;
  width: unset;
  padding: 0;

  @include t-mq($from: $c-accordion--bp) {
    right: 0;
  }
}

.c-accordion__item-content {
  &::before{
    margin-top: -8px;
  }
  padding-left: 0;
  padding-right: 0;
  @include t-mq($from: $c-accordion--bp) {
    padding-left: 0;
    padding-right: 0;
  }
}

.c-accordion__item-title-icon {
  left: 0;
  & + .c-accordion__item-title,
  & + .c-accordion__item-title + .c-accordion__item-subtitle {
    padding-left: 28px;

    @include t-mq($from: $c-accordion--bp) {
      padding-left: 28px;
    }
  }

  @include t-mq($from: $c-accordion--bp) {
    left: 0;
  }
}

.c-accordion--small {
  .c-accordion__item-trigger.c-accordion__item-trigger {
    right: 0;

    @include t-mq($from: $c-accordion--bp) {
      right: 0;
    }
  }

  .c-accordion__item-content {
    @include m-body($c-accordion__body-font--small);
    padding-left: 0;
    padding-right: 0;
    @include t-mq($from: $c-accordion--bp) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .c-accordion__item-title-icon {
    left: 0;
    width: unset;
    height: unset;

    @include t-mq($from: $c-accordion--bp) {
      left: 0;
    }

    & + .c-accordion__item-title,
    & + .c-accordion__item-title + .c-accordion__item-subtitle {
      padding-left: 28px;

      @include t-mq($from: $c-accordion--bp) {
        padding-left: 28px;
      }
    }
  }
}

.c-accordion__item-subtitle {
  color: $color-palette-grey-500;
}

.c-accordion .c-collapse__trigger .m-icon:before {
  color: $color-palette-grey-800;
}
