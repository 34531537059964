/* -------------------------------------------------------------------------
 * TRANSFER TICKET VARS
 *
 */

$w-transfer-ticket--padding:                                                    16px $f-space--medium !default;
$w-transfer-ticket--bg-color:                                                   $f-color__neutral-grey--ultra-light !default;
$w-transfer-ticket--border-radius:                                              $f-radius--large !default;

$w-transfer-ticket__label--line-height:                                         $f-line-height--medium !default;

$w-transfer-ticket__container--margin-bottom:                                   $f-space--tiny !default;

$w-transfer-ticket__right-container--margin-left:                               $f-space--medium !default;

$w-transfer-ticket__small-label--color:                                         $f-color__neutral-grey--semi-dark !default;

$w-transfer-ticket__text-warning--color:                                        $f-color__state-warning--semi-dark !default;
$w-transfer-ticket__text-warning--text-transform:                               uppercase !default;

$w-transfer-ticket__text-empty:                                                 $f-color__neutral-grey !default;
