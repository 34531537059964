/* -------------------------------------------------------------------------
* Tooltip Variables Override
*
*/

$c-tooltip--bg-color:                                         $color-palette-grey-800 !default;
$c-tooltip--border-radius:                                    $radius-large !default;

$c-tooltip--font-size:                                        $typography-body-sm-size !default;
$c-tooltip--line-height:                                      $typography-body-sm-line-height !default;
$c-tooltip__arrow--width:                                     2px !default;
