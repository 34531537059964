.c-price__legend {
  @include m-body($c-price__legend--font);
  color: $theme-color-neutral-500;
}

.c-price__sale-info .c-label--sale {
  @include t-text-crop-enable();
  background-color: $color-palette-red-300;
  color: $color-palette-red-500;
  padding: $spacing-2xs;
  @include m-body(map_get($m-body__mod-sizes, "tiny"));
  font-weight: $font-weight-bold;
}

.c-price__info {
  display: flex;
  align-self: flex-end;
  margin-bottom: 0;
}

.c-price__sale-info,
.c-price__info {
  align-items: center;
}

.c-price__old-price {
  @include m-body(map_get($m-body__mod-sizes, "small"));
  color: $color-palette-primary-500;
}

.c-price__public-price {
  @include t-text-crop-disable();
  padding-top: 0;
  color: $theme-color-neutral-500;
  @include m-body(map_get($m-body__mod-sizes, "tiny"));
}

.c-price__info-icon {
  line-height: $font-line-height-xs;
}

.c-price__info-icon .c-btn__icon::before {
  left: 0;
  font-size: $font-size-md;
  color: $theme-color-neutral-500;
}

.c-price__public-price-detail {
  .m-icon--snowflake {
    display: none;
  }
  color: #6899b6;
  font-weight: $font-weight-semibold;
}

.c-price__label.c-price__label-grayed {
  @include m-body(map_get($m-body__mod-sizes, "tiny"));
  color: $theme-color-neutral-500;
  margin-bottom: $spacing-2xs;
  font-style: italic;
}

.c-price--large .c-price__sale-info .c-label--sale {
  @include t-text-crop-disable();
  padding: $spacing-2xs $spacing-xs;
  @include m-body(map_get($m-body__mod-sizes, "small"));
  font-weight: $font-weight-bold;
}
