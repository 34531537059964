/* -------------------------------------------------------------------------
 * BREAKDOWN TABLE OVERRIDE
 */
.c-breakdown-table{
  .c-breakdown-table__item.c-breakdown-table__item--total {
    &.c-table__item:last-child {
      border-bottom: 0;
      border-top: 2px solid $color-palette-grey-500;
      text-transform: unset;
      .c-breakdown-table__item-left,.c-breakdown-table__item-right{
        font-family: $typography-body-lg-typeface;
        font-size: $typography-body-lg-size;
        font-style: $typography-body-lg-style;
        font-weight: $typography-body-lg-bold-weight,;
        line-height: $typography-body-lg-line-height;
      }
      .c-breakdown-table__item-content.c-breakdown-table__item-content--subitem,
      .c-breakdown-table__item-content.c-breakdown-table__item-content--preitem{
        .c-breakdown-table__item-left,.c-breakdown-table__item-right{
          font-family: $typography-body-sm-typeface;
          font-size: $typography-body-sm-size;
          font-style: $typography-body-sm-style;
          font-weight: $typography-body-sm-weight;
          line-height: $typography-body-sm-line-height;
          letter-spacing: $typography-body-sm-letter-spacing;
        }
      }
    }
  }
  .c-table__item{
    border-top: none;
  }
  .c-breakdown-table__item-content:not(&.c-breakdown-table__item-content--subitem),
  .c-breakdown-table__item-content:not(&.c-breakdown-table__item-content--preitem){
    color: $color-palette-grey-800;
    margin: 0;

    .c-breakdown-table__item-left{
      font-family: $typography-label-thin-typeface;
      font-size: $typography-label-thin-size;
      font-style: $typography-label-thin-style;
      font-weight: $typography-label-thin-weight;
      line-height: $typography-label-thin-line-height;
    }
    .c-breakdown-table__item-right{
      text-align: right;
      font-family: $typography-body-md-bold-typeface;
      font-size: $typography-body-md-bold-size;
      font-style: $typography-body-md-bold-style;
      font-weight: $typography-body-md-bold-weight;
      line-height: $typography-body-md-bold-line-height;
      letter-spacing: $typography-body-md-bold-letter-spacing;
    }
  }
  .c-breakdown-table__item-content.c-breakdown-table__item-content--subitem,
  .c-breakdown-table__item-content.c-breakdown-table__item-content--preitem{
    .c-breakdown-table__item-left, .c-breakdown-table__item-right{
      color: $theme-color-neutral-500;
      font-family: $typography-body-sm-typeface;
      font-size: $typography-body-sm-size;
      font-style: $typography-body-sm-style;
      font-weight: $typography-body-sm-weight;
      line-height: $typography-body-sm-line-height;
      letter-spacing: $typography-body-sm-letter-spacing;
    }
    .c-breakdown-table__item-right{
      text-align: right;
    }
  }
  .c-breakdown-table__item {
    padding-top: $spacing-sm;
    padding-bottom: unset;
    padding-left: unset;
    padding-right: unset;
    &.c-breakdown-table__item--total{
      margin-top: $spacing-lg;
      border-top: 2px solid $color-palette-grey-500;
    }
  }

  .c-breakdown-table__item-content--discount{
    .c-breakdown-table__item-content, .c-breakdown-table__item-content.c-breakdown-table__item-content--subitem > *{
      color: $c-breakdown-table__item-content--color--discount;
    }
  }
}
