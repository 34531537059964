$f-font-family__branding:               'Branding Sunweb' !default;
$f-font-family__branding--fallback:     Arial, Sans-serif !default;

$f-font-family__branding--crop: (
  "font-size":            40,
  "line-height":          1,
  "top":                  10,
  "bottom":               6,
) !default;

@font-face {
  font-family: $f-font-family__branding;
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Branding'), local('Branding Medium'), local('Branding-Medium'),
  url('#{$s-config__path--fonts}/branding/branding--medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/branding/branding--medium.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("branding": "#{$s-config__path--fonts}/branding/branding--medium.woff2"));
}

@font-face {
  font-family: $f-font-family__branding;
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Branding Bold'), local('Branding-Bold'),
  url('#{$s-config__path--fonts}/branding/branding--bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/branding/branding--bold.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("branding--bold": "#{$s-config__path--fonts}/branding/branding--bold.woff2"));
}


@font-face {
  font-family: $f-font-family__branding;
  font-style: normal;
  font-weight: 900;
  font-display: fallback;
  src: local('Branding Bold'), local('Branding-Bold'),
  url('#{$s-config__path--fonts}/branding/branding--bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$s-config__path--fonts}/branding/branding--bold.woff') format('woff'); /* Modern Browsers */
}

@if variable-exists(web-fonts) {
  $web-fonts: map-merge($web-fonts, ("branding--extra-bold": "#{$s-config__path--fonts}/branding/branding--bold.woff2"));
}
