/* -------------------------------------------------------------------------
 * LOADER
 *
 */

.c-brand-loader {
  position: relative;
  display: flex;
  width: $c-brand-loader--size;
  height: $c-brand-loader--size;
}

.c-brand-loader__sparks {
  position: absolute;
  left: $c-brand-loader--positioning + 2;
  top: $c-brand-loader--positioning;
  transform: translate(-$c-brand-loader--positioning, -$c-brand-loader--positioning);
  width: $c-brand-loader--size;
  height: $c-brand-loader--size;
}

.c-brand-loader__spark {
  position: absolute;
  left: $c-brand-loader--positioning;
  width: $c-brand-loader__spark--width;
  height: $c-brand-loader--size;
  transform-origin: center;

  &:first-child {
    transform: rotate($c-brand-loader__spark--rotation);

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 7;
    }

    &:before {
      animation-delay: $c-brand-loader__spark--animation-delay;
    }
  }

  &:nth-child(2) {
    transform: rotate($c-brand-loader__spark--rotation * 2);

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 8;
    }

    &:before {
      animation-delay: $c-brand-loader__spark--animation-delay * 2;
    }
  }

  &:nth-child(3) {
    transform: rotate($c-brand-loader__spark--rotation * 3);

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 9;
    }

    &:before {
      animation-delay: $c-brand-loader__spark--animation-delay * 3;
    }
  }

  &:nth-child(4) {
    transform: rotate(-$c-brand-loader__spark--rotation * 2);

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 4;
    }

    &:before {
      animation-name: c-brand-loader__sparks--brand;
      animation-direction: alternate-reverse;
      animation-delay: $c-brand-loader__spark--animation-delay * 12;
    }
  }

  &:nth-child(5) {
    transform: rotate(-$c-brand-loader__spark--rotation);

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 5;
    }

    &:before {
      animation-name: c-brand-loader__sparks--brand;
      animation-direction: alternate-reverse;
      animation-delay: $c-brand-loader__spark--animation-delay * 14;
    }
  }

  &:last-child {

    &:after {
      animation-delay: $c-brand-loader__spark--animation-delay * 6;
    }

    &:before {
      animation-name: c-brand-loader__sparks--brand;
      animation-direction: alternate-reverse;
      animation-delay: $c-brand-loader__spark--animation-delay * 16;
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: $c-brand-loader__spark--width;
    height: $c-brand-loader__spark--height;
    border-radius: 50px;
    background-color: $c-brand-loader--color;
    transform: scale(0);
    animation-name: c-brand-loader__sparks;
    animation-duration: $c-brand-loader__spark--animation-duration;
    animation-timing-function: cubic-bezier(0, 0, .42, 1);
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  &:before {
    top: 0;
    transform-origin: bottom;
  }

  &:after {
    bottom: 0;
    transform-origin: top;
  }
}

.c-brand-loader__logo {
  width: $c-brand-loader__logo--size;
  height: $c-brand-loader__logo--size;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  left: $c-brand-loader__logo--left;
  align-self: center;
  background-position: center center;
  background-image: url("data:image/svg+xml,%3Csvg id='sundio' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 246.8 352.24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ed2924;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Es-loader%3C/title%3E%3Cpath class='cls-1' d='M149.63,139.92C116.88,127.6,91,117.88,92,93.45c.87-21.59,18.8-32.85,50.49-31.67,24,.89,37,6.36,47.41,10.76,7.12,3,13.7,7,21.14,7.52,17.08,1.27,23-11.91,30.66-27.81,4.21-8.71,3.53-16.52-3.95-24.25-4.8-5-19.35-12.24-30.44-16.69-14.38-5.78-34-10.25-59-11.18C107.15-1.4,72.72,7.45,48.71,25.7c-23,17.44-35.75,42.63-37,72.8-2.69,66.7,50.85,87.26,93.86,103.77,33.11,12.72,61.71,23.7,60.6,51.07-1,24.67-21.69,38.08-56.79,36.79-29.72-1.11-44.52-7.57-55.33-12.3-6.82-3-13.43-7.37-20.61-8.18-17.35-2-22.76,14.27-30.85,29.6C-2.8,309.5,0,320.43,12.76,327.48c16,8.84,42,22.73,90.86,24.53,43.36,1.61,79.5-7.59,104.49-26.6,23.94-18.21,37.29-44.8,38.58-76.87C249.56,177.48,194.15,156.66,149.63,139.92Z' transform='translate(0 0.04)'/%3E%3C/svg%3E");

  animation-name: c-brand-loader__logo;
  animation-duration: $c-brand-loader__spark--animation-duration * 2;
  animation-delay: $c-brand-loader__spark--animation-delay * 12;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes c-brand-loader__rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes c-brand-loader__sparks {
  0% {
    transform: scale3d(0, 0, 0);
  }
  80% {
    transform: scale3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1.2, 1);
  }
}

@keyframes c-brand-loader__sparks--brand {
  0% {
    transform: scale3d(0, 0, 0);
  }
  40% {
    transform: scale3d(1, 1.2, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes c-brand-loader__logo {
  80% {
    transform: rotate(6deg);
  }
  92% {
    transform: rotate(-3deg);
  }
  97% {
    transform: rotate(1.5deg);
  }
  100% {
    transform: rotate(0);
  }
}

// Size modifiers
// -----------------------------------------

.c-brand-loader--tiny {
  width: $c-brand-loader--width--tiny;
  height: $c-brand-loader--width--tiny;
  transform: scale($c-brand-loader--proportion--tiny);
}
