.c-bullet-list {
  color: $color-palette-grey-800;
  font-weight: $typography-body-md-weight;
  letter-spacing: $typography-body-md-letter-spacing;
}

.c-bullet-list--inline > * {
  font-size: $typography-body-md-size;
}

.c-bullet-list--inline {
  display: flex;
  flex-wrap: wrap;

  .c-bullet-list__item {
    display: flex;
  }
}
