.l-site-footer .l-site-footer__bottom .o-swipe-btn {
  margin-bottom: 0;
}

.l-site-footer__bottom.cs-default.cs-default--light .o-swipe-btn {
  color: $color-palette-grey-50;

  &:hover {
    color: $color-palette-primary-700;
  }
}
