/* -------------------------------------------------------------------------
 * UNTRUSTED HTML
 *
 */

 .s-untrusted-html {
  table {
    tr {
      border-bottom: 2px solid $color-palette-grey-300;
      &:nth-child(even) {
        background-color: $theme-color-neutral-100;
      }
    }
  }
 }
