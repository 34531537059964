$c-download-button--border-radius:                    $radius-large !default;
$c-download-button--border-width:                     2px !default;
$c-download-button--border-style:                     solid !default;
$c-download-button--border-color:                     $color-palette-grey-300 !default;
$c-download-button--sibilings--margin-left:           $spacing-md !default;
$c-download-button__title--font-weight:               600 !default;
$c-download-button__font-subtitle:                    map_get($m-body__mod-sizes, "medium") !default;
$c-download-button__download-icon--color:             $color-palette-grey-800 !default;
$c-download-button__download-icon--hover--color:      $color-palette-grey-800 !default;
$c-download-button--inset:                            $spacing-md !default;
$c-download-button__icon--color:                      $color-palette-grey-500 !default;
$c-download-button__download-icon--color:             $color-palette-grey-800 !default;
