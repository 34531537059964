.w-booked-services-list__content .c-actionable-list__item.w-booked-services-list-service-booked .w-booked-services-list__item-icon-right.m-icon--checkmark-circle {
  color: $color-palette-green-500;
}

.w-booked-services-list__no-service-selected{
  font-size: $font-size-xs;

  &::before{
    display: none;
  }
}
