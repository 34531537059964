/* -------------------------------------------------------------------------
 * LABEL
 *
 */

.c-label.c-label--sale {
  &:before {
    margin-bottom: -0.2em;
  }

  &:after {
    margin-top: -0.1em;
  }
}

// Collections with icon
// ------------------------------

.c-label--collection.c-label--with-icon {
  display: inline-block;
  text-align: center;
  font-size: $f-font-size--xs;
  width: $c-label--collection__size;
  height: $c-label--collection__size;
  @include sp-inset($f-space--tiny);
  .c-label__icon {
    display: block;
    padding-bottom: 4px;
    &::#{$m-icons__pseudo-element} {
      font-size: 2.5rem;
    }
  }
  &.cs-theme-totally_snow{
    height: $c-label--collection-totally-snow__height;
  }
}

@import 'main-override';