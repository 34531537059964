/* -------------------------------------------------------------------------
*
* PAGINATION OVERRIDE
*/

.c-btn.c-pagination__button,
a.c-btn.c-pagination__button {
  @include t-text-crop-enable();
  line-height: $c-pagination__button--line-height;
  .m-icon--chevron-left:before {
    left: $c-pagination__button--icon-left;
  }
  @include t-mq($until: $f-breakpoint--sm) {
    height: $spacing-xl;
    width: $spacing-xl;
    line-height: $c-pagination__button--line-height-bp;
  }
  &.c-btn--icon-only {
    .c-btn__icon {
      @include t-mq($until: $f-breakpoint--sm) {
        height: $spacing-xl;
        width: $spacing-xl;
        padding-left: $spacing-xl;
        &::before {
          font-size: $font-size-md;
          top: $c-pagination__button--icon-only--top-bp;
        }
      }
    }
  }
}

a.c-btn.c-pagination__button.c-btn--icon-only {
  line-height: $c-pagination__button--icon-only--line-height;
  @include t-mq($until: $f-breakpoint--sm) {
    line-height: $c-pagination__button--icon-only--line-height-bp;
  }
}

.c-btn.c-pagination__button {
  &.is-disabled {
    opacity: 1;
    color: $color-palette-grey-300;

    .c-btn__icon::before {
      color: $color-palette-grey-300;
    }
  }

  &[data-c-pagination__nav="prev"] {
    margin-right: $spacing-sm;
    @include t-mq($until: $f-breakpoint--sm) {
      margin-right: $spacing-xs;
      .c-btn__icon::before {
        left: $c-pagination__nav-prev-left-bp;
      }
    }
  }
  &[data-c-pagination__nav="next"] {
    margin-left: $spacing-sm;
    @include t-mq($until: $f-breakpoint--sm) {
      margin-left: $spacing-xs;
      .c-btn__icon::before {
        left: $c-pagination__nav-next-left-bp;
      }
    }
  }
}

@include t-mq($until: $f-breakpoint--sm) {
  .c-pagination
    .c-pagination__list
    .c-pagination__list-pages
    .c-pagination__button:first-child:not([data-index="-2"]):not([data-index="-1"]):not([data-index="0"])::before {
    text-indent: $spacing-xl;
  }
}

// Disabled
.c-pagination.is-disabled,
.is-disabled .c-pagination {
  .c-pagination__button {
    &,
    &.is-active {
      color: $color-palette-grey-300;
    }
    &.is-active {
      background-color: $theme-color-neutral-300;
    }
    &.is-disabled {
      color: $color-palette-grey-300;

      .c-btn__icon::before {
        color: $color-palette-grey-300;
      }
    }
  }
  .c-pagination__list-pages {
    .c-pagination__button {
      border-color: transparent;
    }
  }
}
