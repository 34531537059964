.t-account-bookings__slider .c-slider__content {
  align-items: unset;
}

.fr-account-booking__add-booking-modal .c-modal__body:after {
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: block;
  position: absolute;
  background: $fr-account-upcoming-bookings__modal--bg-color;
  box-shadow: $fr-account-upcoming-bookings__modal--shadow;
  height: $fr-account-upcoming-bookings__modal--height;
}

.fr-account-booking__add-booking-form {
  position: relative;
  z-index: 1;
}

.fr-account-booking__add-booking-modal .c-form__section {
  margin-bottom: $fr-account-booking__add-booking-modal--margin;
}


.fr-account-booking__add-booking-modal .c-modal__body {
  padding-bottom: $fr-account-booking__add-booking-modal--padding;
}
