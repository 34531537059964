.c-dropdown__element {
  line-height: $font-line-height-lg;
}

.c-dropdown__wrapper .c-dropdown__clear {
  right: $c-dropdown__clear--right;
  color: $c-dropdown__clear--color;
  background-color: $c-dropdown__element--bg-color;
  border-radius: $c-dropdown--border-radius;
  @include sp-inline($c-dropdown__clear--space-inline);

  &:hover {
    color: $c-dropdown__clear--color--hover;
    background-color: $c-dropdown__element--bg-color;
  }

  .c-btn__icon {
    height: 32px;
    padding-left: 32px;
    &::#{$m-icons__pseudo-element} {
      font-size: $c-dropdown__clear--font-size;
      left: auto;
      right: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  }
}

.c-dropdown.c-dropdown.is-disabled {
  .c-dropdown__icon,
  .c-dropdown__clear {
    background-color: $c-dropdown__disabled--bg-color;
  }
}

.is-disabled .c-dropdown {
  .c-dropdown__icon,
  .c-dropdown__clear {
    background-color: $c-dropdown__disabled--bg-color;
  }

  .c-dropdown__arrow {
    color: $c-dropdown__arrow--disabled--color;
  }

  .c-dropdown__element {
    color: $c-dropdown__element--disabled--color;
    background-color: $e-form-elements--disabled--bg-color;
  }

  &.has-value {
    .c-dropdown__element {
      color: $c-dropdown__element--disabled--color--has-element;
    }
  }
}
