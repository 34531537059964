.c-btn,
a.c-btn {
  @include t-text-crop-disable();
}

.c-btn--loading .c-btn__text {
  display: none;
}

.c-btn,
a.c-btn {
  &[disabled] {
    color: $f-color__brand-primary--light;
    border-color: $f-color__brand-primary--light;
    box-shadow: unset;
  }
}

.c-btn--neutral,
a.c-btn--neutral {
  &[disabled] {
    color: $color-palette-grey-300;
    border-color: $color-palette-grey-300;
  }
}

.c-btn--flow,
a.c-btn--flow {
  &[disabled] {
    background-color: $color-palette-grey-300;
    border-color: $color-palette-grey-300;
    color: $f-color__neutral-white;
  }

  &:hover {
    box-shadow: unset;
  }
}

.c-btn--flow,
a.c-btn--flow,
.c-btn--trust,
a.c-btn--trust {
  &:hover {
    box-shadow: unset;
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px $color-palette-transparent-red-20;
  }
}

.c-btn--flat,
a.c-btn--flat,
.c-btn--flat-neutral,
a.c-btn--flat-neutral {
  &[disabled] {
    border-color: transparent;
  }
  padding-left: $c-btn--spacing-inset-h;
  padding-right: $c-btn--spacing-inset-h;
}

.c-btn--flat-neutral,
a.c-btn--flat-neutral {
  &:hover {
    color: $f-color__neutral-grey--ultra-dark;
  }

  &:focus,
  &:active {
    color: $f-color__neutral-black;
  }

  &[disabled] {
    color: $color-palette-grey-300;
  }
}

.c-btn__icon:before {
  top: calc(50% - 0.75em);
}

// Icon only
.c-btn--icon-only,
a.c-btn--icon-only {
  color: $color-palette-primary-500;

  .c-btn__icon {
    &::#{$m-icons__pseudo-element} {
      font-size: $spacing-lg;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &:hover {
    color: $color-palette-primary-700;
  }

  &:focus,
  &:active {
    color: $color-palette-primary-500;
  }

  &[disabled] {
    color: $color-palette-primary-300;
  }
}
