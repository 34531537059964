/* -------------------------------------------------------------------------
 * BOOKING FILTERABLE SERVICES OVERRIDE
 *
 */

.w-booking-filterable-services__filter__content {
  .c-choice-list__label{
    font-size: $w-booking-filterable-services__labels--font-size;
    font-weight: $w-booking-filterable-services__labels--font-weight;
  }
}

.c-button-group.c-button-group--chips{
  .w-booking-filterable-services-btn-chip:disabled{
    border-color: unset;
  }
}
