.w-search__top-filters {
  border: 2px solid $w-search__top-filters-border-color;
}

.w-search__box-content {
  .e-form-label {
    @include e-form-label();
  }
}

.w-search__filters .w-search__box {
  box-shadow: unset;
}

.w-filter--choice-list[data-is-dynamic="true"] {
  .c-radio-button__label {
    font-size: $font-size-md;
  }
}

.w-search__header-row .w-search__header-btns {
  .w-search__map-action-btn,
  .w-search__sort .c-dropdown__element  {
    border-color: $color-palette-grey-300;

    &:hover {
      border-color: $color-palette-grey-500;
      color: $color-palette-grey-900;
    }

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-palette-transparent-black-25;
    }
  }
}

.w-search__map-action-btn {
  padding-top: 11px;
  padding-bottom: 11px;
}

.w-search__box-collapse .w-search__filter-collapse-trigger {
  margin-top: $spacing-2xs;
}

.w-content-usps--horizontal {
  display: flex;
  justify-content: center;

  .c-bullet-list {
    justify-content: center;

    @include t-mq($until: $f-breakpoint--sm) {
      flex-direction: column;
    }
  }

  .c-bullet-list__item {
    @include t-mq($until: $f-breakpoint--sm) {
      margin-left: 0;
    }
  }
}

.w-search__header-row .w-search__header-btns .w-search__filters-action-btn {
  border-color: $color-palette-grey-300;
  padding-top: 11px;
  padding-bottom: 11px;

  &:hover {
    border-color: $color-palette-grey-500;
    color: $color-palette-grey-900;
  }
}

.w-filter--choice-list[data-is-dynamic="true"] {
  .c-radio-button__label {
    .c-radio-button__element {
      &:after {
        left: 5px;
      }
    }
  }
}
