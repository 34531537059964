/* -------------------------------------------------------------------------
*
* PAGINATION OVERRIDE
*/

$c-pagination__button--size:                                        $spacing-3xl !default;
$c-pagination__button--icon--top--alignment-fix:                    26px !default;
$c-pagination__button--icon--font-size:                             20px !default;
$c-pagination__button--color:                                       unset !default;
$c-pagination__button-truncation--space-left:                       30px !default;

$c-pagination__button--line-height:                                 56px !default;
$c-pagination__button--line-height-bp:                              42px !default;
$c-pagination__button--icon-left:                                   21px !default;

$c-pagination__button--icon-only--line-height:                      52px !default;
$c-pagination__button--icon-only--line-height-bp:                   38px !default;
$c-pagination__button--icon-only--top-bp:                           19px !default;

$c-pagination__nav-prev-left-bp:                                    14px !default;
$c-pagination__nav-next-left-bp:                                    15px !default;

// Active state
$c-pagination__background-color--active:                            $theme-color-neutral-400 !default;
$c-pagination__color--active:                                       unset !default;

// Hover state
$c-pagination__background-color--hover:                             $theme-color-neutral-400 !default;
$c-pagination__color--hover:                                        unset !default;

// Pressed state
$c-pagination__background-color--pressed:                           $theme-color-neutral-400 !default;
$c-pagination__color--pressed:                                      unset !default;

