/* -------------------------------------------------------------------------
 * BOOKING STEPS
 *
 */

.t-bookingsteps .l-site-main {
  padding-top: $t-bookingsteps--padding-top--sm;

  @include t-mq($from: $l-site-main--bp-smaller) {
    padding-top: $t-bookingsteps--padding-top;
  }
}
