/* -------------------------------------------------------------------------
 * SEARCHPAGE
 *
 */

// 12 column grid adjustments

@supports (display: grid) {
  .t-searchpage {
    .l-site__wrapper--medium {
      max-width: $l-column-grid__max-width;
      @include sp-inset-h($l-column-grid__inset-h);

      @include t-mq($from: "sm") {
        @include sp-inset-h($l-column-grid__sm__inset-h);
      }

      @include t-mq($from: "md") {
        @include sp-inset-h($l-column-grid__md__inset-h);
      }

      @include t-mq($from: "lg") {
        @include sp-inset-h($l-column-grid__lg__inset-h);
      }

      .o-layout {
        margin-left: unset;
        display: grid;
        grid-template-columns: repeat($l-column-grid__sml__columns, 1fr);
        column-gap: $l-column-grid__gap;

        @include t-mq($from: "sm") {
          column-gap: $l-column-grid__sm__gap;
        }

        @include t-mq($from: "md") {
          column-gap: $l-column-grid__md__gap;
        }

        .w-search__left {
          @include t-mq($from: "md") {
            grid-column-start: 1;
            grid-column-end: 4;
            width: 100% !important; //important is needed to override stylings applying inportant
          }
        }

        .w-search__right {
          padding-left: unset;
          grid-column-start: 1;
          grid-column-end: -1;
          width: 100% !important; //important is needed to override stylings applying inportant
          @include t-mq($from: "md") {
            grid-column-start: 4;
            grid-column-end: -1;
          }
        }

        .o-layout__item {
          padding-left: unset;
        }
      }
    }

    .o-columns {
      column-gap: $l-column-grid__gap;

      @include t-mq($from: "sm") {
        column-gap: $l-column-grid__sm__gap;
      }

      @include t-mq($from: "md") {
        column-gap: $l-column-grid__md__gap;
      }
    }
    .l-site-footer__main .l-site__wrapper--medium {
      @include t-mq($from: "md") {
        padding-left: $l-column-grid__md__inset-h;
        padding-right: $l-column-grid__md__inset-h;
      }

      @include t-mq($from: "lg") {
        padding-left: $l-column-grid__lg__inset-h;
        padding-right: $l-column-grid__lg__inset-h;
      }
    }
    .w-search__results .c-search-result {
      @supports (display: grid) {
        display: grid;
      }
    }
  }
}
