.fr-destination-exceptional-info__table {
  .c-table__item.fr-destination-exceptional-info__table-row-header {
    background-color: $color-palette-grey-50;
    text-transform: unset;

    .fr-destination-exceptional-info__table-cell {
      color: $color-palette-grey-800;
      font-weight: $typography-body-md-bold-weight;
      font-size: $typography-body-md-bold-size;
    }
  }
}

.fr-destination-exceptional-info__table-cell:first-child {
  font-weight: $typography-body-md-weight;
}

.fr-destination-exceptional-info__table.c-table--stripped
  .c-table__item:nth-child(odd) {
  background-color: $color-palette-grey-50;
}

.fr-destination-exceptional-info__table.c-table--stripped
  .c-table__item:nth-child(even) {
  background-color: $color-palette-grey-100;
}

.t-accommodation {
  .fr-acco-basic-info {
    .fr-acco-basic-info__heading {
      @include t-mq($from: $t-accommodation--bp) {
        align-items: center;
      }
    }
  }

  .fr-acco-description__title-container {
    @include t-mq($from: $fr-acco-description--bp) {
      align-items: center;
    }
  }

  .fr-reviews-overview__score-points {
    .c-number-score {
      .c-number-score__label {
        @include t-mq($from: $fr-reviews-overview--bq) {
          white-space: unset;
        }
      }
      .c-number-score__body {
        display: flex;
        padding-top: $spacing-xs;
      }
    }
    row-gap: unset;
  }

  .fr-reviews-overview-overview__number-score .c-number-score__body {
    font-size: $typography-heading-xl-size;
    margin-right: $spacing-xs;
    padding-right: unset;
    @include t-mq($from: $fr-reviews-overview--bq) {
      font-size: $typography-heading-xl-size;
      padding-right: $spacing-xs;
    }
  }

  .fr-reviews-overview__main-category {
    background-color: $theme-color-neutral-400;
    color: $theme-color-neutral-500;
  }

  .fr-acco-facilities__category-name {
    text-transform: unset;
  }
}

.fr-acco-facilities__category.fr-acco-facilities__category-highlight {
  .fr-acco-facilities__category-name {
    color: $fr-acco-facilities__category-highlight-name--color;
  }
}
