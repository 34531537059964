
/* -------------------------------------------------------------------------
 * FONTS
 *
 * Font families & fallback
 *
 */

// Web fonts variable. Used to preload load them
$web-fonts: ();


// Font imports

@import "foundations__font-families--general-sans";
@import "foundations__font-families--branding";
@import "foundations__font-families--verdana";


// Main font families

$f-font-family__primary:          $f-font-family__general-sans, $f-font-family__general-sans--fallback !default;
$f-font-family__secondary:        $f-font-family__branding, $f-font-family__branding--fallback !default;
$f-font-family__accent:           $f-font-family__general-sans, $f-font-family__general-sans--fallback !default;

$f-font-family__primary--crop:    $f-font-family__general-sans--crop !default;
$f-font-family__secondary--crop:  $f-font-family__branding--crop !default;
$f-font-family__accent--crop:     $f-font-family__general-sans--crop !default;


// FONT FAMILIES MAP

$f-font-families: (
  "primary":    $f-font-family__primary,
  "secondary":  $f-font-family__secondary,
  "accent":     $f-font-family__accent,
) !default;

$f-font-families--crops: (
  "primary":    $f-font-family__primary--crop,
  "secondary":  $f-font-family__secondary--crop,
  "accent":     $f-font-family__accent--crop,
) !default;
