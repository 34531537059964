
/* -------------------------------------------------------------------------
 * THEME VILLAGES Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-villages--enabled {
  .cs-theme-villages {
    @include color-scheme($cs-theme-villages)
  }
}
