/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

@import 'main-override';

// Selectors output
// -------------------------------------------------

.l-site-footer {
}

// -------------------------------------------------

.l-site-footer__main-extra {
  &::before{
    border-color: $l-site-footer__main-extra--separator-border-color;
  }
}

.l-site-footer__bottom {
  // We don't have a color scheme default--semi-dark,
  // so we apply the default--light and change the background-color manually
  background-color: $l-site-footer__bottom--bg-color;

  .c-nav__link {
    color: $l-site-footer__bottom--nav-color;
  }

  .o-swipe-btn {
    margin-bottom: $l-site-footer--swipe-btn__margin-bottom;

    &.o-swipe-btn--left {
      background: linear-gradient(to right, $l-site-footer__bottom--bg-color 40%, rgba($l-site-footer__bottom--bg-color, 0));
    }

    &.o-swipe-btn--right {
      background: linear-gradient(to left, $l-site-footer__bottom--bg-color 40%, rgba($l-site-footer__bottom--bg-color, 0));
    }
  }
}
