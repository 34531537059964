/* -------------------------------------------------------------------------
 * BUTTON
 */

@import "vars-override";

// Variants > Flow
// -------------------------------
// Hover
$c-btn-flow--hover--bg-color:                   $f-color__brand-primary--dark !default;
$c-btn-flow--hover--border-color:               $f-color__brand-primary--dark !default;
$c-btn-flow--hover--shadow:                     $f-shadow--elevated $f-color__neutral-grey--semi-dark !default;

// Focus
$c-btn-flow--pressed--color:                    $f-color__brand-primary--light !default;
$c-btn-flow--pressed--bg-color:                 $f-color__brand-primary--dark !default;
$c-btn-flow--pressed--border-color:             $f-color__brand-primary--dark !default;
