.c-range__label {
  @include t-text-crop-disable();
}


.c-range__current-min,
.c-range__current-max {
  @include m-body($c-range__current-values--font);
}

.c-range__current-max::before {
  color: $theme-color-neutral-500;
}

.is-disabled.c-range,
.is-unresolved.c-range {
  .noUi-connect {
    background: $color-palette-grey-300;
  }

  .noUi-handle:before {
    background-color: $color-palette-grey-300;
  }

  .noUi-target {
    background-color: $theme-color-neutral-200;
  }

  .c-range__current-min,
  .c-range__current-max {
  color: $color-palette-grey-300;
}
}
