.c-tooltip:before {
  padding: $spacing-sm;
  letter-spacing: $typography-body-sm-letter-spacing;
}

.c-tooltip:after {
  content: unset;
}

.c-tooltip {
  &:before,
  &:after  {
    transition-property: none;
  }
}
