/* -------------------------------------------------------------------------
 * VOUCHER STATUS 
 *
 */

 .t-voucher-status .l-site-main {
    padding-top: $t-voucher-status-l-site-main--padding-top;
  
    @include t-mq($from: $l-site-main--bp-smaller) {
      padding-top: $t-voucher-status-l-site-main--padding-top--sm;
    }
  }
