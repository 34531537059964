/* -------------------------------------------------------------------------
 * CHOICE LIST
 *
 */

 $c-choice-list--boxed--border-color:                                 $color-palette-grey-300 !default;
 $c-choice-list--boxed--border-radius:                                $radius-large !default;

 $c-choice-list--boxed--hover--bg-color:                              unset !default;

 $c-choice-list--boxed--selected--bg-color:                           unset !default;
 $c-choice-list--boxed--selected--border-color:                       $color-palette-grey-500 !default;

 $c-choice-list__element--boxed--sp-stack:                            $spacing-md !default;
 $c-choice-list__messages--font-size:                                 $f-font-size--xs !default;
