@if $m-link--enabled and $m-link__mod-discrete--enabled {
  .m-link--discrete {
    color: $color-palette-grey-800;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      color: $color-palette-primary-700;
      text-decoration: underline;
    }
  }
}
