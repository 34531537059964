
/* -------------------------------------------------------------------------
 * THEME TOTALLY SNOW Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-totally_snow--enabled {
  .cs-theme-totally_snow {
    @include color-scheme($cs-theme-totally_snow)
  }
}
