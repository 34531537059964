/* -------------------------------------------------------------------------
 * QuickSearch
 *
 */

@import "main-override";

.w-quick-search {
  @include t-mq($from: $f-breakpoint--sm) {
    margin-left: 0 !important;

    > * {
      margin-left: 0;
    }
  }
}
