/* -------------------------------------------------------------------------
 * RADIO BUTTON OVERRIDE
 */

$c-radio-button__circle--size:                              18px !default;

$c-radio-button__highlight-text--sp-stack:                  unset  !default;

$c-radio-button__circle--border--size:                      2px !default;
$c-radio-button__circle--border--color:                     $color-palette-grey-500 !default;

$c-radio-button__circle--hover--border--color:              $color-palette-grey-800 !default;

$c-radio-button__circle--focus--box-shadow:                 0px 0px 0px 3px $color-palette-transparent-black-25 !default;
$c-radio-button__circle-success--focus--box-shadow:         0px 0px 0px 3px rgba(33, 183, 136, 0.20) !default;
$c-radio-button__circle-warning--focus--box-shadow:         0px 0px 0px 3px rgba(244, 190, 21, 0.2) !default;
$c-radio-button__circle-error--focus--box-shadow:           0px 0px 0px 3px $color-palette-transparent-red-20 !default;          

$c-radio-button__circle--selected--bg-color:                $color-palette-grey-50 !default;
$c-radio-button__circle--selected--border--color:           $color-palette-grey-800 !default;

$c-radio-button__bullet--color:                             $color-palette-grey-800 !default;
$c-radio-button__bullet--size:                              $spacing-xs !default;

$c-radio-button__element--bullet--left:                     14px !default;

$c-radio-button__circle--success--border--color:            $color-palette-green-500 !default;

$c-radio-button__circle--warning--border--color:            $color-palette-yellow-500 !default;

$c-radio-button__circle--error--border--color:              $color-palette-red-500 !default;