.w-booking-car-rental__selected-button,
.w-booking-car-rental-edit__conditions-btn.w-booking-car-rental-edit__conditions-btn {
  font-weight: $font-weight-medium;
  text-decoration: underline;
}

.w-booking-car-rental__selected-button:hover,
.w-booking-car-rental-edit__conditions-btn.w-booking-car-rental-edit__conditions-btn:hover .c-btn__text,
.w-booking-car-rental-edit__conditions-btn.w-booking-car-rental-edit__conditions-btn:hover {
  color: $color-palette-primary-700;
  text-decoration: underline;
}
