.c-modal {
  border-radius: $radius-large;
}

.c-modal__header {
  padding: $spacing-md 52px;
  min-height: 60px;
  box-shadow: $shadow-md-bottom;
}

.c-modal__header-text,
.c-modal__body {
  @include t-text-crop-disable();
}

.c-modal__header-text {
  padding-right: 0;
  text-align: center;
}

.c-modal__body {
  background-color: $color-palette-grey-100;
}

.c-modal__footer {
  padding: $spacing-md;
  box-shadow: $shadow-md-top;
}

.c-modal__close {
  right: 4px;

  .c-btn__icon:before {
    font-size: 20px;
  }
}

