/* -------------------------------------------------------------------------
*
* NUMBER SCORE
*/

$c-number-score__label--padding-bottom:                                 unset !default;
$c-number-score__bubble--color:                                         unset !default;
$c-number-score--color:                                                 unset !default;
$c-number-score--squared-color:                                         unset !default;
$c-number-score__bubble--min-width:                                     unset !default;
$c-number-score--squared-min-width:                                     unset !default;

$c-number-score__bubble--padding:                                       0px !default;
$c-number-score--squared-padding:                                       0px !default;

$c-number-score__label--spacing:                                        $spacing-sm !default;
$c-number-score-variant__grade--border-radius:                          20px !default;