/* -------------------------------------------------------------------------
 * CHOICE LIST
 *
 */

.c-choice-list--boxed:not(.c-choice-list--rich-options) {
  .c-checkbox__label,
  .c-radio-button__label {
    border: 2px solid $c-choice-list--boxed--border-color;
    box-shadow: $shadow-md-bottom;
  }
  .c-checkbox__label:hover,
  .c-radio-button__label:hover {
    border: 2px solid $color-palette-grey-500;
  }

  .c-radio-button.is-disabled .c-radio-button__label,
  .c-checkbox.is-disabled .c-checkbox__label {
    border: 2px solid $color-palette-grey-300;
    background: $color-palette-grey-200;
    .c-radio-button__element:before,
    .c-checkbox__element:before {
      border-color: $color-palette-grey-300;
    }
    .c-radio-button__text,
    .c-checkbox__text,
    .c-checkbox__additional-text,
    .c-choice-list-rich__label {
      color: $color-palette-grey-300;
    }
  }

  .c-radio-button.is-disabled,
  .c-checkbox.is-disabled {
    // Active / Selected / Checked State + Highlight
    .c-checkbox__input:checked + .c-checkbox__label,
    .c-radio-button__input:checked + .c-radio-button__label {
      border-color: $color-palette-grey-300;
      .c-radio-button__element:before {
        border-color: $color-palette-grey-300;
      }
      .c-radio-button__element:after {
        background-color: $color-palette-grey-300;
      }
    }
  }

  // Active / Selected / Checked State + Highlight
  .c-checkbox__input:checked + .c-checkbox__label,
  .c-radio-button__input:checked + .c-radio-button__label {
    border: 2px solid $c-choice-list--boxed--selected--border-color;
    .c-radio-button__element:before {
      border: 2px solid $color-palette-grey-800;
    }
  }

  .c-checkbox__input:focus + .c-checkbox__label,
  .c-radio-button__input:focus ~ .c-radio-button__label {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
  }
}

// Rich
.c-choice-list--rich-options {
  &.c-choice-list--boxed {
    .c-choice-list__option-rich {
      border: 2px solid $c-choice-list--boxed--border-color;
      box-shadow: $shadow-md-bottom;

      &:hover {
        border-color: $color-palette-grey-500;
      }

      // Hover / Focus / Pressed State
      .c-checkbox__input:focus + .c-checkbox__label,
      .c-radio-button__input:focus ~ .c-radio-button__label {
        box-shadow: unset;
      }

      // Active / Selected / Checked State + Highlight
      &.is-checked {
        border: 2px solid $c-choice-list--boxed--selected--border-color;
        .c-radio-button__element:before {
          border: 2px solid $color-palette-grey-800;
        }
      }
    }
  }
}
