/* -------------------------------------------------------------------------
 * COLLECTION TEASER
 *
 */

// Collection labels with icon (spark) should be positioned
.c-collection-teaser {
  .c-label.c-label--with-icon {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
  }
  .c-collection-teaser__description .c-bullet-list__text {
    color: $color-palette-grey-50;
  }
}
.c-collection-teaser--large {
  .c-label.c-label--with-icon {
    left: $c-collection-teaser--large__label--left-position;
  }
}
