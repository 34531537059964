.w-quick-search .c-btn--loading .c-btn__text {
  display: block;
}

.w-quick-search .w-quick-search__submit {
  .c-btn {
    &.c-btn--loading .c-btn__text {
      opacity: 0;
    }
  }
}

@include t-mq($from: $f-breakpoint--sm) {
  .w-quick-search__submit .c-btn {
    bottom: 1px;
  }
}

.w-quick-search__filters.is-disabled {
  .c-dropdown__element,
  .c-dropdown__icon {
    background-color: $color-palette-grey-200;
  }

  .c-dropdown-multiple .c-dropdown__placeholder {
    left: unset;
  }
}
.c-card-price--compact {
  .c-card-price__price {
    align-items: baseline;
  }

  .c-price__info {
    align-self: unset;
  }
}

.c-card-price.c-card-price--compact .c-card-price__title {
  max-height: 48px;
}
