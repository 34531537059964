.c-chip {
  @include t-text-crop-disable();
  padding: $spacing-xs $font-size-md;

  &:hover,
  &:hover .c-chip__icon {
    color: $color-palette-grey-50;
  }

  &:focus {
    color: $theme-color-neutral-800;
    background-color: $theme-color-neutral-400;
    box-shadow: 0px 0px 0px 3px $color-palette-transparent-black-25;
  }

  &:focus .c-chip__icon {
    color: $theme-color-neutral-500;
  }
}

.c-chip .c-chip__icon,
.c-chip.c-chip--linkable .c-chip__link {
  transition-property: color;
  transition-property: $c-chip--transition--property;
  transition-duration: $c-chip--transition--duration;
}

.is-disabled .c-chip,
.c-chip.is-disabled {
  opacity: 1;
  background-color: $theme-color-neutral-300;

  &,
  .c-chip__icon {
    color: $color-palette-grey-500;
  }
}

.c-chip.c-chip--linkable:hover .c-chip__link {
  color: $color-palette-grey-50;
}

.c-chip,
.c-chip.c-chip--removable,
.c-chip.c-chip--linkable {
  @include t-text-crop-disable();
  padding: $spacing-xs $font-size-md;
}

.c-chip.c-chip--removable,
.c-chip.c-chip--linkable {
  padding-right: 33px;
}

.c-chip .c-chip__icon {
  padding: 0 $spacing-md 0 $spacing-xs;
}
