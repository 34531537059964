/* -------------------------------------------------------------------------
 * SEARCHPAGE
 *
 */

@import "main-override";

.t-searchpage {
  .w-search {

    // o-layout item negative top margin fix
    margin-top: $f-space--medium;
  }
}
