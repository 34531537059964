.c-card-avatar__title {
  font-family: $f-font-family__primary;
  font-size: $typography-label-size;
  line-height: $typography-label-line-height;
  font-weight: $typography-label-weight;
}

.c-card-avatar__headings {
  margin-bottom: $spacing-md;
}
