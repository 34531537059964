/* -------------------------------------------------------------------------
* Textbox Variables Override
*
*/

$c-textbox--padding-right--icon-right:                                   $spacing-md !default;

$c-textbox--duration:                                                    $f-duration--normal !default;
$c-textbox--easing:                                                      $f-easing--linear !default;

$c-textbox__element--text-indent--with-icon:                             $spacing-lg !default;
$c-textbox__element--shadow--focus:                                      $e-form-elements--focus--box-shadow !default;
$c-textbox__element--line-height:                                        $e-form-elements__field--line-height !default;

$c-textbox__icons--z-index:                                              $f-z-index--1 !default;

$c-textbox__icon--position:                                              $spacing-md !default;
$c-textbox__icon--font-size:                                             $font-size-sm !default;

$c-textbox__clear--space-inline:                                         $spacing-xs !default;
$c-textbox__clear--font-size:                                            $font-size-xs !default;

// Disabled
$c-textbox__disabled--opacity:                                           $e-form-elements--disabled--opacity !default;
$c-textbox__disabled--bg-color:                                          $e-form-elements--disabled--bg-color !default;
$c-textbox__disabled--color:                                             $e-form-elements--disabled--color !default;

// Success
$c-textbox__element--success--shadow--focus:                             $e-form-elements--focus--box-shadow !default;

// Warning
$c-textbox__element--warning--shadow--focus:                             $e-form-elements--focus--box-shadow !default;

// Error
$c-textbox__element--error--shadow--focus:                               $e-form-elements--focus--box-shadow !default;


// Modifiers
// -----------------------------------------

// Highlighted
$c-textbox__element--highlighted--color:                                 unset !default;

// Messages
$c-textbox__messages--font-size:                                         $font-size-xs !default;
$c-textbox__messages--margin-top:                                        $spacing-xs !default;


// Password requirements
// -----------------------------------------

$c-textbox__password-requirements-container--column-gap:                 $spacing-lg !default;
$c-textbox__password-requirements-container--margin-top:                 $spacing-sm !default;
$c-textbox__password-requirements-container--right-margin:               $spacing-md !default;

$c-textbox__password-requirement--margin-bottom:                         $spacing-2xs !default;
$c-textbox__password-requirement--color:                                 $color-palette-grey-500 !default;
$c-textbox__password-requirement--flex-basis:                            calc(50% - #{$spacing-sm}) !default;

$c-textbox__password-requirement-checked--color:                         $color-palette-green-500 !default;


