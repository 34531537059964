/* -------------------------------------------------------------------------
* Range Variables Override
*
*/

$c-range__line--color:                                                    $theme-color-neutral-400 !default;
$c-range__line--active-color:                                             $color-palette-primary-500 !default;
$c-range__handle--size:                                                   $spacing-lg !default;
$c-range__label--stack:                                                   $spacing-2xs !default;
$c-range__current-values--color:                                          $color-palette-grey-800 !default;
$c-range__current-values--font:                                           map_get($m-body__mod-sizes, "small") !default;
$c-range__handle-dot--size:                                               $spacing-lg !default;
$c-range__handle--border-size:                                            0px !default;
$c-range__handle--shadow:                                                 $shadow-lg-bottom !default;
$c-range__handle--color:                                                  $color-palette-primary-500 !default;
$c-range__current-values--separator-inset:                                $spacing-2xs !default;
$c-range--disabled--opacity:                                              1 !default;
