/* -------------------------------------------------------------------------
 * SEARCH MAP
 */

.w-search-map__search-results-container
  .c-search-result__footer
  .c-price__info-icon
  .c-btn__icon::before {
  left: 0;
}

.w-search-map__refresh-btn__showing-destinations.c-btn--loading {
  min-height: 47.5px;
}
