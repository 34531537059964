/* -------------------------------------------------------------------------
* Textarea Override
*
*/

.c-textarea__element {
  line-height: $c-textarea__element--line-height;
  letter-spacing: $c-textarea__element--letter-spacing;
}

.is-disabled .c-textarea {
  .c-textarea__wrapper {
    background-color: $c-textarea__disabled--bg-color;
  }
  .c-textarea__element{
    background-color: $c-textarea__disabled--bg-color;
    color: $c-textarea__disabled--color;
  }
}

.c-textarea__messages {
  font-family: $typography-body-sm-typeface;
  font-size: $typography-body-sm-size;
  font-style: $typography-body-sm-style;
  font-weight: $typography-body-sm-weight;
  line-height: $typography-body-sm-line-height;
  letter-spacing: $typography-body-sm-letter-spacing;
}
