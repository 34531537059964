.w-flight-bus-selector__collapse-btn {
  &::before,
  &::after {
    display: block;
  }

  &::before {
    margin-bottom: 0;
  }

  &::after {
    margin-top: 0;
  }
}
