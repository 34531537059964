/* -------------------------------------------------------------------------
 * BOOKING CHOICE LIST VARS OVERRIDE
 *
 */

.w-booking-choice-list {
  .c-radio-button__highlight-text {
    background-color: $color-semantic-bg-success;
    color: $color-palette-grey-50;
    @include sp-stack($spacing-xs);
  }
}
