.c-card-price__description {
  @include m-body($c-card-price-description__body-font);
}

.c-card-price .c-card-price__title+.c-card-price__subtitle {
  margin-top: $spacing-2xs;
}

.c-card-price__price {
  line-height: unset;
}

.c-card-price__price .c-price__info,
.c-card-price__price .c-price__number {
  @include t-text-crop-disable();
}

.c-card-price__price .c-price__info {
  margin-bottom: 0;
}

.c-card-price__price .c-price__legend {
  @include m-body(map_get($m-body__mod-sizes, "small"));
}


.c-card-price__price .m-price__currency,
.c-card-price__price .m-price__value {
  @include m-body(map_get($m-body__mod-sizes, "huge"));
}

.c-card-price__info {
  align-items: center;
}

.c-card-price.c-card-price--compact.c-card-price--hover-effect:hover,
.c-card-price.c-card-price--compact {
  box-shadow: $shadow-md-bottom;
}

.c-card-price--compact {
  .m-price__currency,
  .m-price__value {
    @include m-body(map_get($m-body__mod-sizes, "medium"));
    font-weight: 600;
  }

  .m-price__legend {
    @include m-body(map_get($m-body__mod-sizes, "tiny"));
  }
}

.c-card-price--compact .c-card-price__price {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-2xs;
}


.c-card-price.c-card-price--compact .c-card-price__description {
  margin-bottom: $spacing-xs;
}
