.c-download-button {
  box-shadow: $shadow-md-bottom;
}

.c-download-button .m-icon {
  display: flex;
  align-items: center;
}

.c-download-button__title {
  line-height: $typography-label-line-height;
}

.c-download-button__subtitle {
  @include m-body($c-download-button__font-subtitle);
  margin-top: 0;
  color: $theme-color-neutral-500;
}

.c-download-button {
  &:not(.is-unresolved):hover {
    box-shadow: unset;
    background-color: $color-palette-grey-50;
    border-color: $color-palette-grey-500;
  }
}
