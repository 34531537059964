/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

.l-site-main {
  padding-top: $l-site-main__top--padding-top--sm;

  @include t-mq($from: $l-site-main--bp-smaller) {
    @include sp-inset-v($l-site-main--sp-inset-v--from-bp);
    padding-top: $l-site-main__top--padding-top;
  }

  @include t-mq($from: $l-site-main--bp) {
    padding-top: $l-site-main__top--padding-top--sm;
  }
}

// Main top
// -------------------------------------------------

.l-site-main__top {
  margin-top: -1 * $l-site-main__top--padding-top--sm;

  @include t-mq($from: $l-site-main--bp-smaller) {
    margin-top: -1 * $l-site-main__top--padding-top;
  }

  @include t-mq($from: $l-site-main--bp) {
    margin-top: -1 * $l-site-main__top--padding-top--sm;
  }
}

