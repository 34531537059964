.w-booking-cancellation-flow__cancel-modal .w-booking-cancellation-flow__buttons--with-bg {
  background: $w-booking-cancellation-flow__buttons-with-bg--color;
  margin-bottom: -$w-booking-cancellation-flow__buttons-with-bg--margin;
  padding: $w-booking-cancellation-flow__buttons-with-bg--padding;
  box-shadow: $w-booking-cancellation-flow__buttons-with-bg--shadow;
  margin-left: -$w-booking-cancellation-flow__buttons-with-bg--margin;
  margin-right: -$w-booking-cancellation-flow__buttons-with-bg--margin;
  margin-top: $w-booking-cancellation-flow__buttons-with-bg--margin;
}

.w-booking-cancellation-flow__prices-summary {
  box-shadow: $w-booking-cancellation-flow__prices-summary--box-shadow;
  border-radius: $w-booking-cancellation-flow__prices-summary--border-radius;
}
