/* -------------------------------------------------------------------------
* Accordion Variables Override
*
*/

$c-accordion__item-title--text-transform:                                 unset !default;

$c-accordion__item--border-width:                                         1px !default;
$c-accordion__item--border-style:                                         solid !default;
$c-accordion__item--border-color:                                         $color-palette-grey-500 !default;

$c-accordion__item-trigger--bg-color:                                     unset !default;
$c-accordion__item-trigger--size:                                         23px !default;
$c-accordion__item-trigger--bg-color--hover:                              unset !default;
$c-accordion__item-trigger--color:                                        $color-palette-grey-800 !default;
$c-accordion__item-trigger--icon-size:                                    $font-size-md !default;

$c-accordion--sp-inset:                                                   $spacing-md !default;
$c-accordion__item-heading--color--hover:                                 $color-palette-grey-900 !default;

$c-accordion__item-trigger--color--small:                                 $color-palette-grey-800 !default;

$c-accordion--sp-inset--small:                                            $spacing-md !default;
$c-accordion--sp-inset--until-bp--small:                                  $spacing-md !default;
$c-accordion__content--color--small:                                      $color-palette-grey-800 !default;

$c-accordion__body-font--small:                                           map_get($m-body__mod-sizes, "medium") !default;

$c-accordion__item-title-icon--size--small:                               20px !default;
$c-accordion__item-title-icon--size:                                      20px !default;

$c-accordion__item-subtitle--font-weight:                                 $typography-body-xs-weight !default;
$c-accordion__item-subtitle--margin-top:                                  $spacing-2xs !default;
