
/* -------------------------------------------------------------------------
 * THEME BOUTIQUE Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-boutique--enabled {
  .cs-theme-boutique {
    @include color-scheme($cs-theme-boutique)
  }
}
