/* -------------------------------------------------------------------------
*
* NUMBER SCORE
*/

.c-number-score {
  display: flex;
  align-items: center;

  .c-number-score__body {
    font-size: $typography-heading-xl-size;
    font-family: $typography-heading-xl-typeface;
    vertical-align: middle;

    .c-number-score__basis {
      display: none;
    }
  }

  .c-number-score__title {
    font-size: $typography-body-md-bold-size;
    line-height: $typography-body-md-bold-line-height;
    font-weight: $typography-body-md-bold-weight;
    font-family: $typography-body-sm-bold-typeface;
    margin-right: $spacing-sm;
    &::before {
      margin-bottom: unset;
    }
  }

  .c-number-score__grade {
    display: block;
    margin-bottom: 2px;
    line-height: $font-line-height-sm;
  }

  &.c-number-score--bubbled .c-number-score__body:after {
    display: none;
  }

  .c-number-score__label {
    color: $theme-color-neutral-500;
    .c-number-score__button-info,
    .c-btn--icon-only {
      line-height: $typography-heading-lg-line-height;
    }
  }

  .c-number-score__button-info .c-btn__icon::before {
    font-size: $font-size-lg;
  }

  &.c-number-score--bubbled,
  &.c-number-score--squared {
    .c-number-score__grade {
      font-size: $font-size-sm;
      background-color: $theme-color-neutral-300;
      padding: 8px 12px;
      @include sp-inset($spacing-xs, $spacing-sm);
      border-radius: $c-number-score-variant__grade--border-radius;
      line-height: 1.1;
    }
  }
}
