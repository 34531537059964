/* -------------------------------------------------------------------------
 * SMART APP BANNER SUNWEB
 */

.smartbanner .smartbanner-title {
  font-family: $w-smart-app-banner__title--font-family;
}

.smartbanner-ios .smartbanner-button {
  font-family: $w-smart-app-banner__btn--font-family;
}
