/* -------------------------------------------------------------------------
*
* COOKIE CONSENT
*/

.w-cookie-consent {
  .w-cookie-consent-modal-introduction__open-settings-modal-link {
    text-decoration: underline;
  }
}
