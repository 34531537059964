/* -------------------------------------------------------------------------
 * video
 */

 @import "vars-override";
 
$c-video--thumbnail--info-icon-content-color:               $f-color__state-info--semi-dark !default;
$c-video--thumbnail--info-icon-color-hover:                 $f-color__state-info--dark !default;

$c-video--thumbnail--info-icon-center-top:                  52% !default;
$c-video--thumbnail--info-icon-center-left:                 52% !default;