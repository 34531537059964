/* -------------------------------------------------------------------------
 * COLLAPSE
 *
 */

// Component selector output
// --------------------------------------------------


.c-collapse__trigger {
  font-size: $typography-label-size;
  font-weight: $typography-label-weight;
  line-height: $typography-label-line-height;

  &[#{$c-collapse__attr__action}="toggle"],
  &[#{$c-collapse__attr__action}="close"][#{$c-collapse__attr__opened}],
  &[#{$c-collapse__attr__action}="open"]:not([#{$c-collapse__attr__opened}]) {
    height: auto;
  }
}

.c-collapse__trigger .m-icon:before {
  font-size: $font-size-md;
}
