.w-product-map .c-modal__body {
  border-top-left-radius: $radius-large;
  border-top-right-radius: $radius-large;
}

.w-product-map .c-modal__close .c-btn__icon {
  color: $color-palette-grey-50;

  &:hover {
    color: $color-palette-primary-700;
  }
}

.w-product-map .c-modal__header {
  box-shadow: unset;
}

.w-product-map .c-product-card {
  flex-direction: column;
}
