/* -------------------------------------------------------------------------
 * BODY variables
 *
 */

// Material default properties

$m-body__properties: (
  "font-family" :       $e-document__body--font-family,
  "font-family-crop" :  $e-document__body--font-family--crop,
  "font-size":          $typography-body-md-size,
  "line-height":        $typography-body-md-line-height,
  "font-style":         $typography-body-md-style,
  "font-weight":        $typography-body-md-weight,
  "letter-spacing":     $typography-body-md-letter-spacing
) !default;


// Material Size Modifiers

$m-body__mod-sizes--enabled :   true !default;
$m-body__mod-sizes : (
  "tiny" : (
    "font-family" :       $e-document__body--font-family,
    "font-family-crop" :  $e-document__body--font-family--crop,
    "font-size":          $typography-body-xs-size,
    "line-height":        $typography-body-xs-line-height,
    "font-style":         $typography-body-xs-style,
    "font-weight":        $typography-body-xs-weight,
    "letter-spacing":     $typography-body-xs-letter-spacing
  ),
  "small" : (
    "font-family" :       $e-document__body--font-family,
    "font-family-crop" :  $e-document__body--font-family--crop,
    "font-size":          $typography-body-sm-size,
    "line-height":        $typography-body-sm-line-height,
    "font-style":         $typography-body-sm-style,
    "font-weight":        $typography-body-sm-weight,
    "letter-spacing":     $typography-body-sm-letter-spacing
  ),
  "medium" :              $m-body__properties,
  "large" : (
    "font-family":        $e-document__body--font-family,
    "font-family-crop" :  $e-document__body--font-family--crop,
    "font-size":          $typography-body-lg-size,
    "line-height":        $typography-body-lg-line-height,
    "font-style":         $typography-body-lg-style,
    "font-weight":        $typography-body-lg-bold-weight,
    "letter-spacing":     $typography-body-lg-letter-spacing
  ),
  "huge": (
    "font-family":        $e-document__body--font-family,
    "font-family-crop" :  $e-document__body--font-family--crop,
    "font-size":          $typography-body-lg-size,
    "line-height":        $typography-body-lg-line-height,
    "font-style":         $typography-body-lg-style,
    "font-weight":        $typography-body-lg-bold-weight,
    "letter-spacing":     $typography-body-lg-letter-spacing
  )
) !default;
