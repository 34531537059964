
.c-textbox__element {
  line-height: $c-textbox__element--line-height;
}

.is-disabled .c-textbox {
  .c-textbox__wrapper {
    background-color: $c-textbox__disabled--bg-color;
  }
  .c-textbox__element{
    background-color: $c-textbox__disabled--bg-color;
    color: $c-textbox__disabled--color;
  }
  .c-textbox__icon{
    background-color: $c-textbox__disabled--bg-color;
  }
  .c-textbox__clear{
    background-color: $c-textbox__disabled--bg-color;
  }
}

.is-disabled.c-textbox {
  .c-textbox__clear{
    background-color: $c-textbox__disabled--bg-color;
  }
}

.c-textbox__messages {
  font-family: $typography-body-sm-typeface;
  font-size: $typography-body-sm-size;
  font-style: $typography-body-sm-style;
  font-weight: $typography-body-sm-weight;
  line-height: $typography-body-sm-line-height;
  letter-spacing: $typography-body-sm-letter-spacing;
}

.c-textbox__wrapper .c-textbox__clear {
  right: $c-textbox__clear--right;
  color: $c-textbox__clear--color;
  background-color: $c-textbox__wrapper--bg-color;
  border-radius: $c-textbox--border-radius;
  @include sp-inline($c-textbox__clear--space-inline);

  &:hover {
    color: $c-textbox__clear--color--hover;
    background-color: $c-textbox__element--bg-color;
  }

  .c-btn__icon {
    height: 32px;
    padding-left: 32px;
    &::#{$m-icons__pseudo-element} {
      font-size: $c-textbox__clear--font-size;
      left: auto;
      right: 50%;
      transform: translateX(50%) translateY(-50%);
    }
  }
}
