/* -------------------------------------------------------------------------
 *  BOOKING BASKET
 *
 */

.w-booking-basket__services--side-drawer-btn {
  .c-btn__icon::before {
    top: $spacing-xs;
  }
}

.w-booking-basket__mobile-footer.in {
  .w-booking-basket__services--side-drawer-btn {
    .c-btn__icon::before {
      left: 0;
      top: $spacing-sm;
    }
  }
}

.w-booking-basket .w-booking-basket__side-drawer .c-side-drawer__body-content {
  @include t-mq($until: $f-breakpoint--md) {
    bottom: $spacing-lg;
    top: $spacing-lg;
  }
}
