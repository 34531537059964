// Color schemes overrides
// -----------------------------------------

.cs-brand-primary {
  .c-btn__icon {
    color: $c-btn-icon-only--color--cs-primary;
  }
}

.cs-brand-secondary {
  .c-btn__icon {
    color: $c-btn-icon-only--color--cs-secondary;
  }
}
