.c-slider__buttons .c-btn__icon {
  padding-left: 32px;

  &:before {
    font-size: 18px;
    top: calc(50% - 0.75em);
    left: 8px;
    transform: unset;
  }
}

.c-slider__content{
  display: flex;
  align-items: center;
}

.c-slider-video-thumbnail-info-icon{
  border: 2px solid $color-palette-grey-300;
}

.slider-video-thumbnail{
  .c-slider-video-thumbnail-info-icon{
    &:before{
      color: $color-palette-grey-800;
    }
  }
  &:hover{
    .c-slider-video-thumbnail-info-icon{
      border-color: $color-palette-grey-500;
      &:before{
        color: $color-palette-grey-900;
      }
    }
  }
}