/* -------------------------------------------------------------------------
 * SITE HEADER
 */

@import "vars-override";

$fr-header-menu-top__nav-item---link-color--hover:            $cs-brand-primary--light--link-hover !default;
$fr-header-menu-top__nav-item---link-decoration--hover:       none !default;

$l-site-header__main-nav--text-transform:                     uppercase !default;
$l-site-header__top-live-search--nav-v-adjustment:            10px !default;
