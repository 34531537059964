/* -------------------------------------------------------------------------
 * FORM VARIABLES OVERRIDE
 *
 */


$e-form-elements--height:                                 24px !default;
$e-form-elements--sp-inset-h:                             $spacing-md !default;
$e-form-elements--sp-inset-v:                             $spacing-sm !default;

$e-form-elements__field--color:                           $color-palette-grey-800 !default;
$e-form-elements__field--bg-color:                        $color-palette-grey-50 !default;
$e-form-elements__field--border--size:                    2px !default;
$e-form-elements__field--border--type:                    solid !default;
$e-form-elements__field--border-color:                    $color-palette-grey-300 !default;
$e-form-elements__field--border-radius:                   $radius-large !default;
$e-form-elements__field--font-family:                     $font-family-secondary !default;
$e-form-elements__field--font-size:                       $font-size-sm !default;
$e-form-elements__field--line-height:                     $font-line-height-lg !default;
$e-form-elements__field--letter-spacing:                  $typography-body-md-letter-spacing !default;
$e-form-elements__field--box-shadow:                      $shadow-input !default;

$e-form-elements--focus--color:                           $f-color__brand-primary !default;
$e-form-elements--focus--opacity:                         .8 !default;

$e-form-elements__label--display:                         block !default;
$e-form-elements__label--color:                           $theme-color-neutral-500 !default;
$e-form-elements__label--font-family:                     $typography-body-sm-typeface !default;
$e-form-elements__label--font-size:                       $typography-body-sm-size !default;
$e-form-elements__label--font-weight:                     $typography-body-sm-weight !default;
$e-form-elements__label--line-height:                     $typography-body-sm-line-height !default;
$e-form-elements__label--letter-spacing:                  $typography-body-sm-letter-spacing !default;
$e-form-elements__label--text-transform:                  none !default;
$e-form-elements__label--space-stack:                     $spacing-xs !default;

$e-form-elements__icon--color:                            $color-palette-grey-500 !default;
$e-form-elements__placeholder--color:                     $color-palette-grey-500 !default;

$e-form-elements__clear--color:                           $color-palette-grey-500 !default;
$e-form-elements__clear--color--hover:                    $color-palette-grey-700 !default;

$e-form-elements__arrow--bg-color:                        rgba($color-palette-grey-50, 0) !default;

// Label with tooltip
$e-form-label__container--margin-bottom:                  $spacing-xs !default;
$e-form-label__container--max-width:                      calc(100% - #{$spacing-md}) !default;

$e-form-label__tooltip--margin-left:                      $spacing-xs !default;
$e-form-label__tooltip--font-size:                        $typography-body-sm-size !default;
$e-form-label__tooltip--transform:                        translateX(calc(100% + #{$e-form-label__tooltip--margin-left})) !default;
$e-form-label__tooltip--z-index:                          $f-z-index--2 !default;

// States
// -----------------------------------------

// Disabled
$e-form-elements--disabled--opacity:                      1 !default;
$e-form-elements--disabled--color:                        $color-palette-grey-300 !default;
$e-form-elements--disabled--bg-color:                     $color-palette-grey-200 !default;
$e-form-elements__arrow--disabled--color:                 $color-palette-grey-300 !default;

// Hover
$e-form-elements--hover--bg-color:                        $color-palette-grey-50 !default;
$e-form-elements--hover--border-color:                    $color-palette-grey-300 !default;

// Focus / :active
$e-form-elements--focus--bg-color:                        $color-palette-grey-50 !default;
$e-form-elements--focus--border-color:                    $color-palette-grey-300 !default;
$e-form-elements--focus--box-shadow:                      0px 0px 0px 3px rgba(0, 0, 0, 0.20), $shadow-input;

// Pressed
$e-form-elements--pressed--border-color:                  $color-palette-grey-300 !default;

// Selected / Checked
$e-form-elements--selected--bg-color:                     $color-palette-grey-300 !default;
$e-form-elements--selected--border-color:                 $color-palette-grey-300 !default;

// Success
$e-form-elements--success--border-color:                  $color-palette-green-500 !default;

// Warning
$e-form-elements--warning--border-color:                  $color-palette-yellow-500 !default;

// Error
$e-form-elements--error--border-color:                    $color-palette-red-500 !default;
