
/* -------------------------------------------------------------------------
 * THEME XPERIENCE Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-xperience--enabled {
  .cs-theme-xperience {
    @include color-scheme($cs-theme-xperience)
  }
}
