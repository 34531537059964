
/* -------------------------------------------------------------------------
 * COLORS
 *
 * Import all color groups and build the main color map
 */

$f-color--to-delete: #ff00c7 !default;

@import 'foundations__colors-brand';
@import 'foundations__colors-neutral';
@import 'foundations__colors-other';
@import 'foundations__colors-state';
@import 'foundations__colors-theme';

