$c-chip__text--font-size:                             $font-size-xs !default;
$c-chip--line-height:                                 $font-line-height-md !default;
$c-chip__text--color:                                 $color-palette-grey-800 !default;
$c-chip__text--color--dark:                           $color-palette-grey-800 !default;
$c-chip__text--color--highlighted:                    $color-palette-grey-800 !default;
$c-chip--bg-color:                                    $theme-color-neutral-400 !default;
$c-chip--bg-color--dark:                              $theme-color-neutral-400 !default;
$c-chip__icon-color:                                  $theme-color-neutral-500 !default;
$c-chip__icon-color--dark:                            $theme-color-neutral-500 !default;
$c-chip--hover--bg-color:                             $theme-color-neutral-500 !default;
$c-chip--hover--bg-color--dark:                       $theme-color-neutral-500 !default;
$c-chip--transition--property:                        background-color, color !default;
