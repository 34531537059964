/* -------------------------------------------------------------------------
 * CONTENT BLOCK VARIABLES
 */

// Size Modifiers
// -------------------------------

// Large modifier
$c-content-block--large--spacing-stack:          $f-space--medium !default;
$c-content-block__subtitle--color:               unset !default;
