/* -------------------------------------------------------------------------
*
* ROOM LIST
*/

.w-room-list__room-title-icon {
    color: unset;
}

.w-room-list__room-title-text {
    color: $w-room-list__room-title-text--color;
    text-transform: unset;
}

/*
 * Copied styles from the rule ".c-actionable-list__item-room .c-actionable-list__item-room__stock"
 * in actionable-list\main-override.scss
 *
 * NOTE: The full selector is required to override the equivalent CSS rule even if the .w-room-list-item__availability can
 * select the HTML element to style, due to CSS specificity rules.
 */
 .w-room-list-item__availability.c-label--sale.c-label,
 .w-room-list-item__onrequest.c-label--sale.c-label {
    border: unset;
    border-radius: $radius-medium;
    color: $theme-color-neutral-500;
    background-color: $theme-color-neutral-400;
    @include m-body($c-actionable-list__item-label-font);
    font-weight: 600;
    padding: 4px;
}

.w-room-list-item__availability.c-label--sale.c-label {
    color: $w-room-list-item__availability--color;
    background-color: $w-room-list-item__availability--background-color;
 }
.w-room-list-item__availability.w-room-list-item__availability-lastroom.c-label--sale.c-label {
    background-color: $w-room-list-item__availability-lastroom--background-color;
 }
  
.w-room-list-item__onrequest.c-label--sale.c-label {
    text-transform: unset;
}

.w-room-list-item__characteristics-trigger-btn {
    color: $w-room-list-item__characteristics-trigger-btn--color;
}
