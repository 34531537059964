/* -------------------------------------------------------------------------
 * BOOKING TERMS CONDITIONS
 *
 */

.w-booking-terms-conditions__documents-container:not(:last-child) {
  @include sp-stack($spacing-md);
  @include sp-inset($spacing-md);

  .c-collapse__trigger {
    line-height: $font-line-height-xs;
    .c-collapse__trigger-icon:before {
      line-height: $font-line-height-xs;
    }
  }
}
