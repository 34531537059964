/* -------------------------------------------------------------------------
 * TABS OVERRIDE
 *
 */

 $c-tabs__nav--height:                                                  60px !default;
 $c-tabs__nav--height--from-bp:                                         62px !default;
 $c-tabs__nav--height--secondary:                                       59px !default;
 $c-tabs__nav--height--from-mq--secondary:                              62px !default;

 $c-tabs__nav--shadow:                                                  unset !default;

 $c-tabs__nav-button--sp-inset-h:                                       $spacing-md !default;
 $c-tabs__nav-button--after-height--open:                               2px !default;
 $c-tabs__nav-button--after-color--open:                                $color-palette-grey-800 !default;
 $c-tabs__nav-button--color--open:                                      unset !default;
 $c-tabs__nav-button--bg-color--hover:                                  unset !default;
 $c-tabs__nav-button--bg-color--open:                                   unset !default;

 $c-tabs__nav-list--featured-width--until-bp:                           unset !default;
 $c-tabs__nav-list--featured-width--until-bp--smaller:                  unset !default;

 $c-tabs__nav-item--shadow:                                             unset !default;
 $c-tabs__nav-item--bg-color:                                           unset !default;
 $c-tabs__nav-item--bg-color--secondary:                                unset !default;
 $c-tabs__nav-item-width--until-bp:                                     unset !default;
 $c-tabs__nav-item-width--until-bp--smaller:                            unset !default;
 $c-tabs__nav-item-width--until-bp--smallest:                           unset !default;

 $c-tabs__nav--bg-color--secondary:                                     unset !default;
 $c-tabs__nav-button--height--secondary:                                unset !default;
 $c-tabs__nav-button--height--secondary--until-bp:                      unset !default;
 $c-tabs__nav-button--sp-inset--secondary:                              $spacing-md !default;
 $c-tabs__nav-button--bg-color--hover--secondary:                       unset !default;
 $c-tabs__nav-button--color--hover--secondary:                          unset !default;
 $c-tabs__nav-button--bg-color--open--secondary:                        unset !default;
 $c-tabs__nav-button--color--open--secondary:                           unset !default;
 $c-tabs__nav-button--sp-stack--until-bp:                               unset !default;

 $c-tabs__nav--border-bottom-width--secondary:                          0px !default;
 
 $c-tabs__nav-button--icon-adjust--until-bp:                            0px !default;
 $c-tabs__nav-button--icon-font-size--until-bp:                         $typography-body-md-size !default;
 $c-tabs__nav-button--min-width--from-bp:                               unset !default;


