.c-modal {
  border-radius: $radius-large;
}

.c-modal-v2__header {
  padding: $spacing-md;
  box-shadow: $shadow-md-bottom;
}

.c-modal-v2__header-text,
.c-modal__body {
  @include t-text-crop-disable();
}

.c-modal-v2__footer {
  padding: $spacing-md;
  box-shadow: $shadow-md-top;
}

.c-modal-v2__close {
  color: $color-palette-grey-800;
  position: absolute;
  right: $spacing-md;

  &:hover {
    color: $color-palette-grey-400;
  }

  .c-btn__icon:before {
    font-size: 20px;
  }
}

.c-modal-v2__header-title {
  text-align: center;
  padding-right: 0;
}

.c-modal-v2__header-text {
  padding: 0 $spacing-2xl;
}

.c-modal-v2__close .c-btn--icon-only {
  padding-left: 30px;
}


.c-modal-v2__header .c-btn--icon-only .c-btn__icon {
  padding-left: 30px;
}

.c-modal-v2__pre-header {
  position: absolute;
  padding-left: 0;
}

.c-modal-v2__header .c-modal-v2__header-logo-img {
  margin-bottom: 16px;
}

.c-modal-v2__go-back-arrow {
  position: absolute;
  right: 0;
}

.c-modal-v2:not(.has-title) .c-modal-v2__header {
  min-height: 60px;
}

.c-modal-v2__go-back-arrow:hover {
  color: $color-palette-grey-400;
}

.c-modal__inner-slider .c-modal-v2__header {
  .c-modal-v2__close{
    color: $color-palette-grey-50;

    &:hover{
      color: $color-palette-primary-700;
    }
  }
}
