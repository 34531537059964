/* -------------------------------------------------------------------------
 * TABLE OVERRIDE
 *
 */

.c-table {
  border-radius: $radius-medium;
  overflow: hidden;
}

.c-table__item,
.c-table--stripped .c-table__item {
  @include sp-inset($spacing-md);
}
