.w-selected-state__price-overview {
  .c-breakdown-table__item--total {
    .w-selected-state__price-overview__item--highlighted {
      .c-breakdown-table__item-left,
      .c-breakdown-table__item-right {
        color: $color-palette-grey-800;
      }
    }
  }
}

.w-selected-state__conditional-message--wrapper {
  border-radius: $radius-large;
  @include sp-inset($spacing-md);
}

.w-selected-state__price-overview .c-table__item {
  @include sp-inset-h(unset);
  @include sp-inset-v($spacing-xs);

  .c-breakdown-table__item-content--subitem {
    .c-breakdown-table__item-left,
    .c-breakdown-table__item-right {
      color: $color-palette-grey-800;
      font-size: $font-size-md;
    }
  }
}
