/* -------------------------------------------------------------------------
*
* SEARCH RESULT
*/


$c-search-result__footer--shadow--from-mq:                                      unset !default;
$c-search-result__action--border-width:                                         2px !default;
$c-search-result__action--color:                                                unset !default;
$c-search-result__action--font-weight:                                          500 !default;
