/* -------------------------------------------------------------------------
 * STEP INDICATOR VARS OVERRIDE
 */

// Progress
$c-steps__progress--bp:                                "sml" !default;

$c-steps--progress-sp-v:                               $spacing-2xs !default;
$c-steps--progress__link-color:                        $color-palette-grey-800 !default;
$c-steps--progress__link-color--active:                $color-palette-grey-900 !default;
$c-steps--progress__link-weight--active:               $typography-body-xs-weight !default;
$c-steps--progress__link-weight:                       $typography-body-xs-weight !default;
$c-steps--progress__link-font-size:                    $typography-body-xs-size !default;
$c-steps--progress__link-font-size--from-bp:           $typography-body-xs-size !default;
$c-steps--progress__link-min-height:                   45px !default;
$c-steps--progress__link-height:                       auto !default;


$c-steps--progress__bullet-size:                       $spacing-lg !default;
$c-steps--progress__bullet-bg-color--active:           $color-palette-primary-700 !default;
$c-steps--progress__bullet-shadow-opacity:             1 !default;
$c-steps--progress__bullet-bg-color--disabled:         $color-palette-grey-300 !default;
$c-steps--progress__bullet-weight:                     $font-weight-bold !default;
$c-steps--progress__bullet-color:                      $color-palette-grey-500 !default;
$c-steps--progress__bullet-margin-bottom:              $spacing-xs !default;