/* -------------------------------------------------------------------------
 * RADIO BUTTON OVERRIDE
 */

.c-radio-button__label {
  font-size: $typography-label-size;
  font-weight: $typography-label-weight;
  line-height: $typography-label-line-height;
  letter-spacing: $typography-label-letter-spacing;
  font-style: $typography-label-style;
}

.c-radio-button__text-wrapper {
  align-items: center;

  &.c-radio-button__text-wrapper--additional-text {
    align-self: center;
  }
}

.c-radio-button__element {
  align-self: center;

  // Used as radio BULLET
  &::after {
    left: $c-radio-button__element--bullet--left;
  }
}

.c-radio-button__label:hover {
  .c-radio-button__element::before {
    box-shadow: unset;
  }
}

.c-radio-button__input:focus ~ .c-radio-button__label {
  .c-radio-button__element::before {
    box-shadow: $c-radio-button__circle--focus--box-shadow;
  }
}

// Validation States
// -------------------------------------------------

.c-radio-button {
  &.has-success {
    .c-radio-button__label:hover {
      .c-radio-button__element::before {
        border-color: $color-palette-green-700;
      }
    }
    .c-radio-button__input:focus ~ .c-radio-button__label {
      .c-radio-button__element::before {
        box-shadow: $c-radio-button__circle-success--focus--box-shadow;
      }
    }
    .c-radio-button__element {
      // Used as radio BULLET
      &::after {
        background-color: $color-palette-green-500;
      }
    }
    .c-radio-button__text {
      color: $color-palette-green-500;
    }
  }

  &.has-warning {
    .c-radio-button__label:hover {
      .c-radio-button__element::before {
        border-color: $color-palette-yellow-700;
      }
    }
    .c-radio-button__input:focus ~ .c-radio-button__label {
      .c-radio-button__element::before {
        box-shadow: $c-radio-button__circle-warning--focus--box-shadow;
      }
    }
    .c-radio-button__element {
      // Used as radio BULLET
      &::after {
        background-color: $color-palette-yellow-500;
      }
    }
    .c-radio-button__text {
      color: $color-palette-yellow-500;
    }
  }

  &.has-error {
    .c-radio-button__label:hover {
      .c-radio-button__element::before {
        border-color: $color-palette-red-700;
      }
    }
    .c-radio-button__input:focus ~ .c-radio-button__label {
      .c-radio-button__element::before {
        box-shadow: $c-radio-button__circle-error--focus--box-shadow;
      }
    }
    .c-radio-button__element {
      // Used as radio BULLET
      &::after {
        background-color: $color-palette-red-500;
      }
    }
    .c-radio-button__text {
      color: $color-palette-red-500;
    }
  }
}
