$c-actionable-list__item-link-font:                               map_get($m-body__mod-sizes, "medium") !default;
$c-actionable-list__item--selected--color:                        $color-palette-grey-800 !default;
$c-actionable-list__item-link__mark--color:                       $color-palette-primary-500 !default;
$c-actionable-list__item-link--selected__mark--color:             $color-palette-primary-500 !default;
$c-actionable-list__item-label-font:                              map_get($m-body__mod-sizes, "tiny") !default;
$c-actionable-list__item-extended__icon--color:                   $color-palette-grey-800 !default;

$c-actionable-list--room--title-font:                             map_get($m-body__mod-sizes, "huge") !default;
$c-actionable-list--room--description-font:                       map_get($m-body__mod-sizes, "medium") !default;
$c-actionable-list--no-room--title-font:                          map_get($m-body__mod-sizes, "medium") !default;
$c-actionable-list__item__room-price-legend--color:               $theme-color-neutral-500 !default;
$c-actionable-list--room__price-title:                            map_get($m-heading__mod-sizes, "large") !default;
