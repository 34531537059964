/* -------------------------------------------------------------------------
 * BOOKING PRICE OVERVIEW
 *
 */

.w-booking-price-overview {
  .c-breakdown-table__item-content--discount {
    border-bottom: unset;
  }
}
