.c-card {
  overflow: hidden;
  border-radius: $radius-large;
  border: unset;
  box-shadow: $shadow-md-bottom;
}

.c-card__body,
.c-card__title,
.c-card__subtitle {
  @include t-text-crop-disable();
}

.c-card__footer,
.c-card__body {
  padding: $spacing-lg;
}

.c-card__footer {
  padding-top: 0;
}

.c-card__subtitle {
  margin-top: 0;
}

.c-card__body-heading {
  margin-bottom: 16px;
}

.c-card__body p {
  @include m-body($c-card-paragraphs__body-font);
}

.c-card__footer {
  .c-btn--flat,
  a.c-btn--flat {
    padding: 10px 22px;
    width: 100%;
    float: unset;
  }
}

.c-card .c-card__header-label.c-card__header-label--top-right {
  top: $spacing-md;
  right: $spacing-md;
  border-top-right-radius: $radius-medium;
  border-bottom-right-radius: $radius-medium;
}


.c-card--top-title {
  .c-card__title {
    color: $color-palette-grey-800;
    background-color: unset;
    margin: 0;
    padding: 0;
  }
  .c-card__title .c-card__title-link {
    color: $color-palette-grey-800;
  }

  .c-card__header + .c-card__body {
    .c-card__title {
      width: unset;
      position: unset;
      left: unset;
      top: unset;
      margin: unset;
      background-color: unset;
    }
  }

  .c-card__body-heading {
    margin-bottom: $spacing-md;
  }
}

.c-card__header-label.c-label--with-icon.c-label--collection {
  height: unset;
  width: unset;
  right: 16px;
  top: 16px;
  left: auto;
  transform: unset;
  @include m-body($c-card-label__body-font);
  padding: $spacing-2xs $spacing-xs;
  font-weight: 600;
  .c-label__icon {
    display: none;
  }
}
