/* -------------------------------------------------------------------------
 * ACCOMMODATION
 *
 */

@import "main-override";

.t-accommodation .l-site-main {
  padding-top: $t-accommodation-l-site-main--padding-top;

  @include t-mq($from: $l-site-main--bp-smaller) {
    padding-top: $t-accommodation-l-site-main--padding-top--sm;
  }
}
