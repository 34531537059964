/* -------------------------------------------------------------------------
 * ACCOUNT BOOKINGS
 *
 */

.t-account-bookings .l-site-main {
  padding-top: $t-account-bookings-l-site-main--padding-top;

  @include t-mq($from: $l-site-main--bp-smaller) {
    padding-top: $t-account-bookings-l-site-main--padding-top--sm;
  }
}

@import "main-override";
