/* -------------------------------------------------------------------------
*
* SEARCH MAP
*/

.w-search-map__mobile-filters-btn {
  border: 2px solid $color-palette-grey-300;
  border-color: $color-palette-grey-300;

  &.c-btn--icon-only .c-btn__icon:before {
    left: 50%;

    @include t-mq($until: $w-search-map--first-bp) {
      margin-top: -1px;
    }
  }

  &,
  &[disabled],
  &:focus {
    background-color: $color-palette-grey-50;
    border-color: $color-palette-grey-300;

    &.c-btn--icon-only .c-btn__icon:before {
      color: $color-palette-grey-900;
    }
  }

  &:hover,
  &:active {
    background-color: $color-palette-grey-50;
    border-color: $color-palette-grey-700;

    &.c-btn--icon-only .c-btn__icon:before {
      color: $color-palette-grey-900;
    }
  }
}
