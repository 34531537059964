/* -------------------------------------------------------------------------
 * COLLECTION ROW
 *
 */

.c-collection-row {
  .c-collection-row__item {
    box-shadow: $shadow-md-bottom;
    border-radius: $radius-large;
  }
  .c-collection-row__item-icon .m-icon--chevron-right::before {
    font-size: $font-size-xl;
  }

  &:not([class*="c-collection-row--expanded"]) {
    .c-collection-row__item-content {
      border-radius: 0px $radius-large $radius-large 0px;
    }
    .c-collection-row__item-image {
      border-radius: $radius-large 0px 0px $radius-large;
      .c-img {
        border-radius: $radius-large 0px 0px $radius-large;
      }
    }
    .c-collection-row__item-label.c-collection-row__item-label {
      position: absolute;
      top: $spacing-xs;
      width: auto;
      font-size: $font-size-xs;
      font-weight: $typography-label-weight;
      border-radius: $radius-medium;
      @include sp-inset($spacing-2xs);
    }
  }
  .c-collection-row__item-content {
    @include sp-inset($spacing-lg);
  }
}

[class*="c-collection-row--expanded"] {
  .c-collection-row__item {
    .c-collection-row__item-content {
      position: relative;
      background-color: $color-palette-grey-50;
      @include t-mq ($until: "md") {
        @include sp-inset($spacing-md);
        padding-right: $spacing-xl;
      }
      
      .c-collection-row__item-title {
        font-size: $typography-body-lg-size;
        color: $color-palette-grey-800;
      }
      .c-collection-row__item-subtitle {
        font-size: $typography-body-md-size;
        color: $color-palette-grey-500;
      }
      @include t-mq ($until: "md") {
        .c-collection-row__item-title,
        .c-collection-row__item-subtitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
    @include t-mq ($until: "md") {
      .c-collection-row__item-label.c-collection-row__item-label{
        position:absolute;
        border-radius: $radius-medium;
        padding: $spacing-2xs;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
      }
    }
  }
}
