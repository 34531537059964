/* -------------------------------------------------------------------------
*
* DD GRID
*/

// Cell decoration
.w-dd-grid__cell,
button.w-dd-grid__cell {
  transition: unset;
}
