/* -------------------------------------------------------------------------
*
* FAQ FEEDBACK
*/

.w-faq-feedback__button {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $color-palette-primary-700;
    text-decoration: underline;
  }
}
