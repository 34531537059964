/* -------------------------------------------------------------------------
*
* REVIEWS LIST
*/

.w-reviews-list__score {
  margin-bottom: $spacing-sm;
  .c-number-score__body {
    padding: unset;
    margin-bottom: unset;
    @include t-mq($from: $w-reviews-list__item--bq) {
      padding: unset;
    }
  }
}

.w-reviews-list__item-scores {
  @include t-mq($until: $w-reviews-list__item--bq) {
    column-gap: $spacing-sm;
  }

  .c-number-score__body {
    font-size: $font-size-lg;
    background-color: unset;
    border-radius: unset;
    color: unset;
    min-width: unset;
    padding-right: $spacing-2xs;
    margin-right: $spacing-xs;

    @include t-mq($until: $w-reviews-list__item--bq) {
      padding-left: unset;
    }

    .c-number-score__grade {
      @include sp-inset-h($spacing-md);
    }
  }

  .c-number-score__title {
    font-weight: $font-weight-semibold;
  }
}
