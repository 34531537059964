/* -------------------------------------------------------------------------
 * 12 column grid
 *
 */

// Default
$l-column-grid__columns:                                                                   4 !default;
$l-column-grid__inset-h:                                                                   1rem !default;
$l-column-grid__gap:                                                                       1rem !default;
$l-column-grid__max-width:                                                                 1440px !default;

// breakpoint--sm
$l-column-grid__sm__columns:                                                               8 !default;
$l-column-grid__sm__inset-h:                                                               1.5rem !default;
$l-column-grid__sm__gap:                                                                   1.5rem !default;

// breakpoint--sml
$l-column-grid__sml__columns:                                                              12 !default;

// breakpoint--md
$l-column-grid__md__inset-h:                                                               2rem !default;
$l-column-grid__md__gap:                                                                   2rem !default;

// breakpoint--lg
$l-column-grid__lg__inset-h:                                                               4rem !default;