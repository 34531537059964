/* -------------------------------------------------------------------------
 * BOOKING PARTICIPANTS BADGE
 *
 */

.w-booking-participants-badge--participants-header {
  &[data-c-collapse__opened] {
    .c-btn__icon::before {
      left: 0;
    }
  }
}
