
/* -------------------------------------------------------------------------
 * THEME CYCLING Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-cycling--enabled {
  .cs-theme-cycling {
    @include color-scheme($cs-theme-cycling)
  }
}
