/* -------------------------------------------------------------------------
*
* ROOM OCCUPANCY
*/

$w-room-occupancy__room-title--text-transform:                                  unset !default;
$w-room-occupancy__room-title--color:                                           $color-palette-grey-800 !default;

$w-room-occupancy__price--color:                                                $color-palette-grey-800 !default;

$w-room-occupancy__availability--bg-color:                                      $color-palette-yellow-500 !default;
$w-room-occupancy__availability--color:                                         $color-palette-grey-50 !default;
$w-room-occupancy--border-color:                                                $color-palette-grey-300 !default;
