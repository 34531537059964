/* -------------------------------------------------------------------------
 * LABEL
 *
 */

.c-label {
  &.c-label--with-icon, &.c-label--badge, &.c-label--sale,
  &.c-label--success, &.c-label--error, &.c-label--warning, &.c-label--info,
  &.c-label--collection, &.c-label--canceled-state{
    text-align: left;
    display: inline-block;
    align-items: center;
    font-family: $typography-body-sm-bold-typeface;
    font-size: $typography-body-sm-bold-size;
    font-style: $typography-body-sm-bold-style;
    font-weight: $typography-body-sm-bold-weight;
    line-height: $typography-body-sm-bold-line-height;
    letter-spacing: $typography-body-sm-bold-letter-spacing;
    @include sp-inset($spacing-2xs,$spacing-xs);
    border-radius: $radius-medium;

    &.c-label--small{
      font-family: $typography-body-xs-typeface;
      font-size:  $typography-body-xs-size;
      font-style: $typography-body-xs-style;
      font-weight: $typography-body-xs-weight;
      line-height: $typography-body-xs-line-height;
      letter-spacing: $typography-body-xs-letter-spacing;
      @include sp-inset-squish($spacing-2xs);
    }
    &.c-label--collection:not(.c-label--with-icon){
      top: $spacing-xs;
      left: $spacing-xs;
      width: fit-content;
      height: fit-content;
    }
  }
  .c-label__icon {
    margin-right: $spacing-2xs;
  }

  &.c-label--with-icon.c-label--collection{
    font-family: $typography-body-xs-typeface;
    font-size: $typography-body-xs-size;
    font-style: $typography-body-xs-style;
    font-weight: $font-weight-semibold;
    line-height: $typography-body-xs-line-height;
    letter-spacing: $typography-body-xs-letter-spacing;
    text-align: center;
    @include sp-inset($spacing-sm, 0);
    .c-label__icon {
      display: block;
      padding-bottom: 0px;
      &::#{$m-icons__pseudo-element} {
        font-size: $font-size-3xl;
      }
    }
  }
}

.c-label--with-icon, .c-label--badge{
  &:not(.c-label--collection){
    background-color: $theme-color-neutral-400;
    color: $theme-color-neutral-500;
  }
}

// Sale
.c-label.c-label--sale {
  background-color: $color-palette-primary-500;
  color: $color-palette-grey-50;
}

// Extra State Canceled
.c-label.c-label--canceled-state{
  background-color: $color-palette-grey-800;
  color: $color-palette-grey-50;
}

// BRAND
.cs-brand-primary.c-label--badge{
  background-color: map_get($cs-brand-primary, 'background');
  color: map_get($cs-brand-primary, 'text');
}



// State
// ------------------------------
.cs-state-success.c-label--success, .cs-state-success--white.c-label--success{
  background-color: map_get($cs-state-success, 'background');
  color: map_get($cs-state-success, 'text');
}
.cs-state-warning.c-label--warning,.cs-state-warning--white.c-label--warning {
  background-color: map_get($cs-state-warning, 'background');
  color: map_get($cs-state-warning, 'text');
}
.cs-state-error.c-label--error, .cs-state-error--white.c-label--error {
  background-color: $color-palette-red-500;
  color: map_get($cs-state-error, 'text');
}
.cs-state-info.c-label--info, .cs-state-info--white.c-label--info {
  background-color: map_get($cs-state-info, 'background');
  color: map_get($cs-state-info, 'text');
}

