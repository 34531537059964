@if $m-price--enabled {
  .m-price {
    @include t-text-crop-disable();
    line-height: normal;
  }

  .m-price__value,
  .m-price__currency {
    @include m-heading(map_get($m-heading__mod-sizes, "medium"));
  }

  .m-price__suffix {
    @include m-body(map_get($m-body__mod-sizes, "tiny"));
    color: $theme-color-neutral-500;
  }

  .m-price--small,
  .m-price--small .m-price__currency {
    font-size: $font-size-xl;
  }

  .m-price--large {
    .m-price__value,
    .m-price__currency {
      font-size: $typography-heading-2xl-size;
      line-height: $typography-heading-2xl-line-height;
    }
  }
}

