/* -------------------------------------------------------------------------
 * BOOKING FILTERABLE SERVICES VARS OVERRIDE
 *
 */

$w-booking-filterable-services__header-active--background-color:                          $f-color__neutral-grey--light !default;

$w-booking-filterable-services__btn--shadow:                                              unset !default;
$w-booking-filterable-services__btn--active--bg-color:                                    unset !default;
$w-booking-filterable-services__btn--active--color:                                       unset !default;
$w-booking-filterable-services__btn--active--border-color:                                unset !default;
$w-booking-filterable-services__btn--active--shadow:                                      unset !default;

$w-booking-filterable-services__tabs__nav-button--color--open:                            $color-palette-grey-900 !default;
$w-booking-filterable-services__header-active--color:                                     $color-palette-grey-800 !default;
$w-booking-filterable-services__filter-info__price--color:                                $color-palette-grey-800 !default;
