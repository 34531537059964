/* -------------------------------------------------------------------------
 * TABS OVERRIDE
 *
 */

.c-tabs__nav {
  background-color: unset;

  .c-tabs__nav-item--featured {
    &.is-open:hover {
      &:after {
        display: block;
      }
    }
    &:hover::after {
      display: none;
    }
  }
}

.c-tabs__nav-item {
  &.is-open {
    &:after {
      top: unset;
      bottom: 0;
    }
  }
  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $c-tabs__nav-button--after-height--open;
    background-color: $c-tabs__nav-button--after-color--open;
  }
}

.c-tabs__nav-button,
a.c-tabs__nav-button {
  @include t-mq($until: $c-tabs--bp) {
    @include sp-inset-v($spacing-md);
  }
  .c-btn__icon {
    @include t-mq($until: $c-tabs--bp) {
      display: inline-block;
      margin-right: $spacing-xs;
    }
  }

  .c-btn__text {
    font-size: $typography-body-md-size;
    @include t-mq($until: $c-tabs--bp) {
      display: initial;
      font-size: $typography-body-md-size;
    }
  }
}

.c-tabs__nav-item--featured {
  .c-tabs__nav-button {
    text-wrap: nowrap;
  }
}

// Secondary
.c-tabs__nav--secondary {
  .c-tabs__nav-item--featured .c-tabs__nav-button,
  .c-tabs__nav-button {
    .c-btn__icon {
      @include t-mq($until: $c-tabs--bp) {
      }
    }
  }
  .c-tabs__nav-item--featured {
    &.is-open {
      &:after {
        top: unset;
        bottom: 0;
      }
    }
    &:hover::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: $c-tabs__nav-button--after-height--open;
      background-color: $c-tabs__nav-button--after-color--open;
    }
  }
}
