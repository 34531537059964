/* -------------------------------------------------------------------------
 * NUMBER STEPPER VARS OVERRIDE
 *
 */

$c-number-stepper--height:                                  32px !default;
$c-number-stepper--width:                                   ($c-number-stepper--height * 3) !default;
$c-number-stepper--border-radius:                           $radius-rounded !default;

$c-number-stepper__input--font-size:                        $e-form-elements__field--font-size !default;
$c-number-stepper__input--color:                            $e-form-elements__field--color !default;
$c-number-stepper__input--bg-color:                         unset !default;

$c-number-stepper__btn--color:                              $color-palette-grey-50 !default;
$c-number-stepper__btn--bg-color:                           $color-palette-grey-500 !default;
$c-number-stepper__btn--hover--bg-color:                    $color-palette-grey-700 !default;

$c-number-stepper__input-disabled--color:                   $c-number-stepper__btn--color !default;
$c-number-stepper__input-disabled--opacity:                 .5 !default;
