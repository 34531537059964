
/* -------------------------------------------------------------------------
 * SPACES
 *
 */


// Main space

$f-space:                     $spacing-lg !default;

// Space variants

$f-space--none:               0 !default;
$f-space--tiny:               $spacing-xs !default;
$f-space--small:              $spacing-sm !default;
$f-space--medium:             $f-space !default;
$f-space--large:              $spacing-3xl !default;
$f-space--huge:               $spacing-5xl !default;
$f-space--giant:              round($f-space * 5) !default;
