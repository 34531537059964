
$c-btn--text-transform:                         unset !default;
$c-btn--font-family:                            $f-font-family__primary !default;
$c-btn--font-weight:                            $typography-label-weight !default;
$c-btn--font-size:                              $typography-label-size !default;
$c-btn--line-height:                            $typography-label-line-height !default;
$c-btn--border-width:                           2px !default;
$c-btn--spacing-inset-v:                        $spacing-sm - $c-btn--border-width !default;
$c-btn--spacing-inset-h:                        $spacing-lg - $c-btn--border-width !default;
$c-btn__disabled--opacity:                      1 !default;

// Variants > Default
// -------------------------------

$c-btn--color:                                  $f-color__neutral-black !default;

// Hover
$c-btn--hover--color:                           $f-color__neutral-grey--ultra-dark !default;
$c-btn--hover--border-color:                    $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn--pressed--color:                         $f-color__neutral-black !default;
$c-btn--pressed--border-color:                  $f-color__brand-primary !default;

// Focus
$c-btn--focus--shadow:                          0px 0px 0px 3px $color-palette-transparent-red-20 !default;
$c-btn--focus--border-color:                    $f-color__brand-primary !default;
$c-btn--focus--color:                           $f-color__neutral-black !default;

// Variants > Secondary
// -------------------------------

$c-btn-secondary--color:                        $f-color__neutral-black !default;
$c-btn-secondary--bg-color:                     $f-color__neutral-white !default;
$c-btn-secondary--border-color:                 $f-color__brand-primary !default;
$c-btn-secondary--shadow:                       none !default;

// Hover
$c-btn-secondary--hover--color:                 $f-color__neutral-grey--ultra-dark !default;
$c-btn-secondary--hover--bg-color:              $f-color__neutral-white !default;
$c-btn-secondary--hover--border-color:          $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn-secondary--pressed--color:               $f-color__neutral-black !default;
$c-btn-secondary--pressed--bg-color:            $f-color__neutral-white !default;
$c-btn-secondary--pressed--border-color:        $f-color__brand-primary !default;

// Focus
$c-btn-secondary--focus--shadow:                0px 0px 0px 3px $color-palette-transparent-red-20 !default;
$c-btn-secondary--focus--border-color:          $f-color__brand-primary !default;
$c-btn-secondary--focus--color:                 $f-color__neutral-black !default;

// Variants > Flow
// -------------------------------

// Hover
$c-btn-flow--hover--bg-color:                   $f-color__brand-primary--semi-dark !default;
$c-btn-flow--hover--border-color:               $f-color__brand-primary--semi-dark !default;

// Pressed
$c-btn-flow--pressed--bg-color:                 $f-color__brand-primary!default;
$c-btn-flow--pressed--border-color:             $f-color__brand-primary !default;
$c-btn-flow--pressed--color:                    $f-color__neutral-white !default;

// Focus
$c-btn-flow--focus--shadow:                     0px 0px 0px 3px $color-palette-transparent-red-20 !default;
$c-btn-flow--focus--border-color:               $f-color__brand-primary !default;
$c-btn-flow--focus--bg-color:                   $f-color__brand-primary !default;
$c-btn-flow--focus--color:                      $f-color__neutral-white !default;

// Variants > Neutral
// -------------------------------

$c-btn-neutral--color:                          $f-color__neutral-black !default;
$c-btn-neutral--border-color:                   $color-palette-grey-300 !default;

// Hover
$c-btn-neutral--hover--color:                   $color-palette-grey-900 !default;
$c-btn-neutral--hover--border-color:            $color-palette-grey-500 !default;

// Focus
$c-btn-neutral--focus--color:                   $color-palette-grey-800 !default;
$c-btn-neutral--focus--border-color:            $color-palette-grey-300 !default;
$c-btn-neutral--focus--shadow:                  0px 0px 0px 3px $color-palette-transparent-black-25 !default;

// Pressed
$c-btn-neutral--pressed--color:                 $color-palette-grey-800 !default;
$c-btn-neutral--pressed--border-color:          $color-palette-grey-300  !default;

// Variants > Trust
// -------------------------------

$c-btn-trust--font-size:                        $typography-label-size !default;
$c-btn-trust--bg-color:                         $f-color__neutral-white !default;
$c-btn-trust--border-color:                     $f-color__brand-primary !default;
$c-btn-trust--box-shadow:                       none !default;
$c-btn-trust--sp-inset-v:                       $c-btn--spacing-inset-v !default;
$c-btn-trust--color:                            $f-color__neutral-black !default;

// Hover
$c-btn-trust--hover--bg-color:                  $f-color__neutral-white !default;
$c-btn-trust--hover--border-color:              $f-color__brand-primary--semi-dark  !default;
$c-btn-trust--hover--color:                     $f-color__neutral-grey--ultra-dark !default;

// Pressed
$c-btn-trust--pressed--bg-color:                $f-color__neutral-white !default;
$c-btn-trust--pressed--border-color:            $f-color__brand-primary !default;
$c-btn-trust--pressed--color:                   $f-color__neutral-black !default;

// Focus
$c-btn-trust--focus--bg-color:                  $f-color__neutral-white !default;
$c-btn-trust--focus--border-color:              $f-color__brand-primary !default;

// Variants > Flat
// -------------------------------
$c-btn-flat--color:                             $f-color__brand-primary !default;
$c-btn-flat--bg-color:                          transparent !default;
$c-btn-flat--border-color:                      transparent !default;

// Hover
$c-btn-flat--hover--color:                      $f-color__brand-primary--semi-dark !default;
$c-btn-flat--hover--bg-color:                   transparent !default;
$c-btn-flat--hover--border-color:               transparent !default;

// Pressed
$c-btn-flat--pressed--color:                    $f-color__brand-primary !default;
$c-btn-flat--pressed--bg-color:                 transparent !default;
$c-btn-flat--pressed--border-color:             transparent !default;

// Variants > Flat Neutral
// -------------------------------

$c-btn-flat-neutral--color:                     $f-color__neutral-black !default;

// Variants > Delete
// -------------------------------
$c-btn-delete--color:                           $f-color__brand-primary !default;

// Variants > Icon only
// -------------------------------
$c-btn-icon-only--size:                         $spacing-3xl !default;

// Loader
// -------------------------------
$c-btn__loader--space:                          1.5em !default;
