// With gradient modifier
.c-floating-box--with-gradient {
  .c-floating-box__header::before {
    background: linear-gradient($f-color__neutral-white, rgba($f-color__neutral-white, 0));
  }

  .c-floating-box__footer::before {
    background: linear-gradient(rgba($f-color__neutral-white, 0), $f-color__neutral-white);
  }
}
