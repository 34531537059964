
/* -------------------------------------------------------------------------
 * THEME SECRETS Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-secrets--enabled {
  .cs-theme-secrets {
    @include color-scheme($cs-theme-secrets)
  }
}
