/* -------------------------------------------------------------------------
 * SITE HEADER
 *
 */

.l-site-header__main {
  @include t-mq($from: $l-site-header--bp) {
    padding-bottom: $spacing-xs;
  }
  .l-site-header__main-left {
    @include t-mq($until: $l-site-header--bp) {
      padding-right: $spacing-sm;
    }
  }
}

.l-site-header__main-right {
  .c-nav__list, .c-nav__form-submit {
    text-transform: $l-site-header__main-nav--text-transform;
  }
}

// Fragments
// -------------------------------------------------
@import 'fr-header-menu-top';