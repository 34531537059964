/* -------------------------------------------------------------------------
 * ICONS variables
 *
 */


$s-config__path--fonts:               "../assets/fonts" !default;


// Material variables
// --------------------------------------------------

$m-icons__class-name:                 "m-icon" !default;
$m-icons__font-family:                "m-icons" !default;
$m-icons__pseudo-element:             "before" !default;
$m-icons__sizes:                      (
  "tiny":     .5em,
  "small":    .75em,
  "medium":   1.5em,
  "large":    1.8em,
  "huge":     2.5em,
  "giant":    3em
) !default;
$m-icons__class-names:                () !default;


$m-icon__glyphs: (
"1-person-circle" : "\E001",
"1-person-travelling" : "\E002",
"1-person" : "\E003",
"2-persons" : "\E004",
"airplane-departure" : "\E005",
"airplane-flexible" : "\E006",
"airplane-left" : "\E007",
"airplane-return" : "\E008",
"airplane-right" : "\E009",
"airplane-top-left" : "\E00A",
"airplane-top-right" : "\E00B",
"airplane" : "\E00C",
"android" : "\E00D",
"apple" : "\E00E",
"apps" : "\E00F",
"arrow-down-small" : "\E010",
"arrow-down" : "\E011",
"arrow-left-small" : "\E012",
"arrow-left" : "\E013",
"arrow-right-small" : "\E014",
"arrow-right" : "\E015",
"arrow-up-small" : "\E016",
"arrow-up" : "\E017",
"attachment" : "\E018",
"baby" : "\E019",
"backpack-checkmark" : "\E01A",
"backpack" : "\E01B",
"beach" : "\E01C",
"bed" : "\E01D",
"bin" : "\E01E",
"bow" : "\E01F",
"bubble" : "\E020",
"building" : "\E021",
"bus-front" : "\E022",
"bus-left" : "\E023",
"bus-right" : "\E024",
"cake" : "\E025",
"calendar-check" : "\E026",
"calendar" : "\E027",
"cancelled" : "\E028",
"car-door" : "\E029",
"car-front" : "\E02A",
"car" : "\E02B",
"certificate" : "\E02C",
"checkbox-check" : "\E02D",
"checkbox" : "\E02E",
"checklist-info" : "\E02F",
"checklist" : "\E030",
"checkmark-circle" : "\E031",
"checkmark-small" : "\E032",
"checkmark" : "\E033",
"chevron-down-small" : "\E034",
"chevron-down" : "\E035",
"chevron-left-small" : "\E036",
"chevron-left" : "\E037",
"chevron-right-small" : "\E038",
"chevron-right" : "\E039",
"chevron-up-small" : "\E03A",
"chevron-up" : "\E03B",
"child" : "\E03C",
"clock" : "\E03D",
"cloud" : "\E03E",
"cocktail" : "\E03F",
"cogwheel" : "\E040",
"copy" : "\E041",
"creditcard-check" : "\E042",
"creditcard" : "\E043",
"cross-country-skiing" : "\E044",
"cross-small" : "\E045",
"cross-square" : "\E046",
"cross" : "\E047",
"culinary" : "\E048",
"cutlery" : "\E049",
"delete-account" : "\E04A",
"deposit" : "\E04B",
"download-circle" : "\E04C",
"download-file" : "\E04D",
"duration" : "\E04E",
"earth" : "\E04F",
"eliza" : "\E050",
"emergency-phone" : "\E051",
"euro" : "\E052",
"eye-off" : "\E053",
"eye" : "\E054",
"face-sad" : "\E055",
"facebook" : "\E056",
"family" : "\E057",
"file" : "\E058",
"files" : "\E059",
"filter-square" : "\E05A",
"filter" : "\E05B",
"fire" : "\E05C",
"flag" : "\E05D",
"flamingo" : "\E05E",
"funpark" : "\E05F",
"gift" : "\E060",
"gogo" : "\E061",
"golf-cart" : "\E062",
"golf-club" : "\E063",
"group" : "\E064",
"headset" : "\E065",
"health-and-safety" : "\E066",
"health" : "\E067",
"heart-fill" : "\E068",
"heart" : "\E069",
"home" : "\E06A",
"honeymoon" : "\E06B",
"hourglass" : "\E06C",
"image" : "\E06D",
"info-composition" : "\E06E",
"information-circle-fill" : "\E06F",
"information-circle" : "\E070",
"instagram" : "\E071",
"invoice" : "\E072",
"kids" : "\E073",
"language" : "\E074",
"lesson" : "\E075",
"lift-cabin-big" : "\E076",
"lift-cabin-small" : "\E077",
"lift-chair-front" : "\E078",
"lift-chair-side" : "\E079",
"lift-drag" : "\E07A",
"link" : "\E07B",
"list" : "\E07C",
"lock-badge" : "\E07D",
"lock" : "\E07E",
"logout" : "\E07F",
"luggage-check" : "\E080",
"luggage" : "\E081",
"magazine" : "\E082",
"mail" : "\E083",
"mandatory-costs" : "\E084",
"map" : "\E085",
"medal" : "\E086",
"menu-hamburger-square" : "\E087",
"menu-hamburger" : "\E088",
"menu-kebab" : "\E089",
"menu-meatballs" : "\E08A",
"minus-small" : "\E08B",
"minus-square" : "\E08C",
"minus" : "\E08D",
"mobile-phone" : "\E08E",
"money" : "\E08F",
"mountains-down" : "\E090",
"mountains-glacier" : "\E091",
"mountains-slope" : "\E092",
"mountains-up" : "\E093",
"mountains" : "\E094",
"mute" : "\E095",
"optional-facilities" : "\E096",
"order" : "\E097",
"parking" : "\E098",
"pause" : "\E099",
"pdf-file" : "\E09A",
"pearl" : "\E09B",
"pencil" : "\E09C",
"phone" : "\E09D",
"pin-group" : "\E09E",
"pin-info" : "\E09F",
"pin-map" : "\E0A0",
"pin" : "\E0A1",
"pinterest" : "\E0A2",
"plane-earth" : "\E0A3",
"play" : "\E0A4",
"plus-small" : "\E0A5",
"plus-square" : "\E0A6",
"plus" : "\E0A7",
"popular-village" : "\E0A8",
"primavera" : "\E0A9",
"question-circle" : "\E0AA",
"question-square" : "\E0AB",
"quotation" : "\E0AC",
"radio-button-check" : "\E0AD",
"radio-button" : "\E0AE",
"receipt" : "\E0AF",
"refresh" : "\E0B0",
"school" : "\E0B1",
"search" : "\E0B2",
"share-ios" : "\E0B3",
"share" : "\E0B4",
"shield-check" : "\E0B5",
"ship" : "\E0B6",
"ski-boots" : "\E0B7",
"ski-helmet" : "\E0B8",
"ski-material" : "\E0B9",
"ski-pass" : "\E0BA",
"skier" : "\E0BB",
"slippers" : "\E0BC",
"snowboard-material" : "\E0BD",
"snowboarder" : "\E0BE",
"snowflake" : "\E0BF",
"snowheight" : "\E0C0",
"spade" : "\E0C1",
"spark-atlantis" : "\E0C2",
"spark-totally" : "\E0C3",
"spark" : "\E0C4",
"sports-leisure" : "\E0C5",
"spotify" : "\E0C6",
"star-full-fill" : "\E0C7",
"star-half-fill" : "\E0C8",
"star" : "\E0C9",
"steering-wheel" : "\E0CA",
"stroller" : "\E0CB",
"suitcase-add" : "\E0CC",
"suitcase" : "\E0CD",
"sun" : "\E0CE",
"sunweb" : "\E0CF",
"sustainable" : "\E0D0",
"swap" : "\E0D1",
"swimming-pool" : "\E0D2",
"synchronise" : "\E0D3",
"tag" : "\E0D4",
"thumbs-down" : "\E0D5",
"thumbs-up" : "\E0D6",
"ticket-flight" : "\E0D7",
"ticket-general" : "\E0D8",
"timeline" : "\E0D9",
"toast" : "\E0DA",
"tomorrowland" : "\E0DB",
"totally" : "\E0DC",
"train" : "\E0DD",
"transmission-automatic" : "\E0DE",
"transmission-manual" : "\E0DF",
"twitter" : "\E0E0",
"umbrella" : "\E0E1",
"upgrade" : "\E0E2",
"volume" : "\E0E3",
"voucher" : "\E0E4",
"wallet" : "\E0E5",
"warning-circle" : "\E0E6",
"warning-square" : "\E0E7",
"warning-triangle" : "\E0E8",
"weather" : "\E0E9",
"wellness" : "\E0EA",
"whatsapp" : "\E0EB",
"youtube" : "\E0EC",
"zoom-in" : "\E0ED",
"zoom-out" : "\E0EE",

);


// Save glyph modifier class names to $brand__class-names
// --------------------------------------------------

@if variable-exists(class-names) {

  @each $_icon-name, $_icon-glyph in $m-icon__glyphs {

    $m-icons__class-names: append($m-icons__class-names, (
      'name': '#{$_icon-name}',
      'class': 'm-icon--#{$_icon-name}',
    ));

  }

  $class-names: map-merge($class-names, (
      'm-icons': $m-icons__class-names
  ));

}

// Declare is-unresolved state variables
// ---------------------------------------------------

$m-icon--unresolved--text-background:               $f-color__neutral-grey--light !default;
$m-icon--unresolved--text-background--width:        auto !default;
$m-icon--unresolved--text-background--height:       100% !default;
$m-icon--unresolved--text-background--top:          11px !default;
$m-icon--unresolved--text-background--left:         0 !default;
$m-icon--unresolved--animation-duration:            2s !default;
$m-icon--unresolved--animation-bullet--width:       20px !default;
$m-icon--unresolved--animation-angle:               220deg !default;
$m-icon--unresolved--animation-startposition:       -10px !default;
$m-icon--unresolved--animation-startcolor:          $f-color__neutral-white--transparent !default;
$m-icon--unresolved--animation-endcolor:            rgba($f-color__neutral-grey--ultra-light, .7) !default;
$m-icon--unresolved--pseudo-element:                null;
$m-icon--unresolved--border-radius:                 $f-radius--medium !default;

@if ($m-icons__pseudo-element == "after") {
  $m-icon--unresolved--pseudo-element: "before" !default;
}
@else {
  $m-icon--unresolved--pseudo-element: "after" !default;
}

// Save glyphs to $ds-tokens
// --------------------------------------------------
@if global_variable_exists(ds-tokens) {

  // Setup the the needed variables for populating ds-tokens
  $m-icon__glyph--values: ();

  // Construct the values for the GLYPH variant
  @each $_icon-name, $_icon-glyph in $m-icon__glyphs {
    $m-icon__glyph--values: append($m-icon__glyph--values, (
      'name': '#{$_icon-name}',
      'class': 'm-icon--#{$_icon-name}',
    ));
  }

  // Add ICONS > GLYPH to DS-TOKENS (material)
  $ds-tokens: map-ensure-nested-keys($ds-tokens, 'material', 'm-icon', 'glyph');
  $ds-tokens: map-deep-set($ds-tokens, 'material', 'm-icon', 'glyph', (
    'description': 'Defines the icon shape',
    'values': $m-icon__glyph--values
  ));

}
