
/* -------------------------------------------------------------------------
 * THEME EXCELLENT Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-excellent--enabled {
  .cs-theme-excellent {
    @include color-scheme($cs-theme-excellent)
  }
}
