.c-floating-box__body-content {
  @include m-body($c-autocomplete__font);
}

.c-autocomplete--destinations {
  .c-autocomplete__result {
    > *[data-attribute="caption"] {
      @include m-body($c-autocomplete__font);
      margin-bottom: 0;
    }

    [data-attribute="count"][data-attribute="count"],
    [data-attribute="parent"][data-attribute="parent"] {
      @include m-body($c-autocomplete__font-subtitle);
      color: $color-palette-grey-500;
    }

    [data-attribute="count"] {
      &::before { content: ""; }
      &::after { content: ""; }
    }

    .c-autocomplete__results-parents {
      width: 100%;
      padding-left: 36px;
    }
  }
}

.c-autocomplete__result [data-attribute="count"] {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.c-autocomplete__result>[data-attribute]:after {
  content: '';
}
