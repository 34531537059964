/* -------------------------------------------------------------------------
 * Price variables
 *
 */

@import "vars-override";

$m-price--font-weight:                    900 !default;
$m-price--font-family:                    $f-font-family__secondary !default;
$m-price--font-family--crop:              $f-font-family__secondary--crop !default;
$m-price__currency--font-family:          $f-font-family__secondary !default;

$m-price__currency--large--font-weight:   900 !default;
