/* -------------------------------------------------------------------------
 * CHECKBOX OVERRIDE
 */

.c-checkbox__label {
  font-size: $typography-label-size;
  font-weight: $typography-label-weight;
  line-height: $typography-label-line-height;
  letter-spacing: $typography-label-letter-spacing;
  font-style: $typography-label-style;
}

.c-checkbox__additional-text {
  font-size: $typography-body-md-size;
  font-weight: $typography-body-md-weight;
  line-height: $typography-body-md-line-height;
  letter-spacing: $typography-body-md-letter-spacing;
  font-style: $typography-body-md-style;
}

.c-checkbox__element {
  align-self: center;
}

// Hover / Focus State
.c-checkbox__label:hover,
.c-checkbox__input:focus ~ .c-checkbox__label {
  .c-checkbox__element {
    background-color: transparent;
  }
}
.c-checkbox__input:focus ~ .c-checkbox__label {
  .c-checkbox__element {
    &::before {
      box-shadow: $c-checkbox__element--focus--box-shadow;
    }
  }
}

.c-checkbox__input:checked ~ .c-checkbox__label:hover {
  .c-checkbox__element::before {
    background-color: $color-palette-green-700;
    border: $color-palette-green-700;
  }
}

// Pressed / Active
.c-checkbox__label:active .c-checkbox__element {
  background-color: transparent;
}

.c-checkbox {
  &.has-warning {
    .c-checkbox__text,
    .c-checkbox__additional-text {
      color: $color-palette-yellow-500;
    }

    // Hover / Focus State
    .c-checkbox__label:hover {
      .c-checkbox__element::before {
        border-color: $color-palette-yellow-700;
      }
    }

    .c-checkbox__input:focus ~ .c-checkbox__label {
      .c-checkbox__element::before {
        box-shadow: $c-checkbox__element--warning-focus--box-shadow;
      }
    }

    // Pressed / Active
    .c-checkbox__label:active .c-checkbox__element::before {
      background-color: $color-palette-yellow-500;
      border: $color-palette-yellow-500;
    }

    .c-checkbox__input:checked ~ .c-checkbox__label:hover {
      .c-checkbox__element::before {
        background-color: $color-palette-yellow-700;
        border: $color-palette-yellow-700;
      }
    }

    // Selected / Checked State
    .c-checkbox__input:checked ~ .c-checkbox__label {
      .c-checkbox__element::before {
        background-color: $color-palette-yellow-500;
        border-color: $color-palette-yellow-500;
      }
    }
  }

  &.has-error {
    .c-checkbox__text,
    .c-checkbox__additional-text {
      color: $color-palette-red-500;
    }

    // Hover / Focus State
    .c-checkbox__label:hover {
      .c-checkbox__element::before {
        border-color: $color-palette-red-700;
      }
    }

    .c-checkbox__input:focus ~ .c-checkbox__label {
      .c-checkbox__element::before {
        box-shadow: $c-checkbox__element--error-focus--box-shadow;
      }
    }

    // Pressed / Active
    .c-checkbox__label:active .c-checkbox__element::before {
      background-color: $color-palette-red-500;
      border: $color-palette-red-500;
    }

    .c-checkbox__input:checked ~ .c-checkbox__label:hover {
      .c-checkbox__element::before {
        background-color: $color-palette-red-700;
        border: $color-palette-red-700;
      }
    }

    // Selected / Checked State
    .c-checkbox__input:checked ~ .c-checkbox__label {
      .c-checkbox__element::before {
        background-color: $color-palette-red-500;
        border-color: $color-palette-red-500;
      }
    }
  }
}

.c-checkbox.is-disabled {
  opacity: 1;

  .c-checkbox__label,
  .c-checkbox__additional-text {
    color: $color-palette-grey-300;
  }

  .c-checkbox__element:before {
    border-color: $color-palette-grey-300;
  }
  .c-checkbox__input:checked ~ .c-checkbox__label .c-checkbox__element:before {
    background-color: $color-palette-green-300;
    border-color: $color-palette-green-300;
  }
}

.c-checkbox__element:after {
  border-color: $color-palette-grey-50;
}
