/* -------------------------------------------------------------------------
 * PRODUCT ADS
 *
 */

// Overrides

$w-product-ads--padding:                                                    $spacing-lg $spacing-md !default;

$w-product-ads--background--background-color:                               $theme-color-neutral-300 !default;
$w-product-ads--background--border-radius:                                  $radius-extra-large !default;

$w-product-ads--modal--btn--color:                                          $theme-color-neutral-500 !default;

$w-product-ads--header:                                                     $spacing-md !default;

// Breakpoint -------------------------------------------------------------

$w-product-ads--bq--padding:                                                $spacing-3xl !default;
$w-product-ads--bq--header:                                                 $spacing-lg !default;
