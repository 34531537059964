
/* -------------------------------------------------------------------------
 * THEME ATLANTIS Color Scheme
 *
 */

// Color Scheme output

@if $cs-theme-atlantis--enabled {
  .cs-theme-atlantis {
    @include color-scheme($cs-theme-atlantis)
  }
}
