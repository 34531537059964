.fr-account-booking-payment-status__container {
  box-shadow: $shadow-md-bottom;
  border-radius: $radius-large;
}

.fr-account-booking-edit-link {
  text-decoration: unset;
}

.fr-account-booking-edit-link:hover .fr-account-booking-edit-link__text {
  text-decoration: unset;
}

.fr-account-booking-term__service-edit-link.c-btn--icon-only {
  color: $color-palette-grey-800;
}
